import React from 'react';
import { CollapsibleSection } from '@estimateone/frontend-components';
import styles from './styles.scss';

type CollapsibleProductInfoProps = {
  longDescription: string;
  isCollapsed: boolean;
};

const CollapsibleProductInfo: React.VFC<CollapsibleProductInfoProps> = ({
  longDescription,
  isCollapsed,
}) => (
  <CollapsibleSection isCollapsed={isCollapsed}>
    <div>
      <hr className={styles.divider} />
      {longDescription}
    </div>
  </CollapsibleSection>
);

export default CollapsibleProductInfo;
