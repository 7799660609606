export default class LoggedOutError extends Error {
  public code: number;

  constructor(public message: string = 'not logged in') {
    super(message);
    this.name = 'LoggedOutError';
    this.code = 419;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
