import { FORM_ERROR } from 'final-form';
import { TransformedFile } from '../../FileUploader';
import {
  QuoteFormCommonFieldName,
  QuoteFormCommonPropertyFieldMap,
  QuoteFormCommonPropertyPath,
} from '@ascension/enums';
import { FieldError } from '../../FinalForm/types';
import { ApiError } from '@api/fragments/Errors/types';
import { QuoteExtraFile, QuoteFile } from '@api/fragments/Quotes/types';
import { UncommittedFileInput } from '@ascension/_gqltypes/subbie.generated';

export const quoteFileToFile = (file: QuoteFile | QuoteExtraFile): TransformedFile => ({
  ...file,
  id: file.id.toString(),
  name: file.fileName,
  size: file.fileSize,
  type: undefined,
});

export const fileToFileInput = ({
  name: fileName,
  size: fileSize,
  type: contentType,
  hash,
}: TransformedFile): UncommittedFileInput => ({
  fileName,
  fileSize,
  contentType,
  hash,
});

type CustomFieldErrorValue = (FieldError | Record<string, unknown>)[];
type CustomFieldError = { [key: string]: CustomFieldErrorValue };

type MapErrorValue = ApiError | FieldError;
type QuoteFormCommonApiErrorsMap = {
  [field in QuoteFormCommonFieldName | typeof FORM_ERROR]?: FieldError[];
};

const getFieldNameByPropertyPath = (
  p: QuoteFormCommonPropertyPath,
): QuoteFormCommonFieldName | false =>
  Object.values(QuoteFormCommonPropertyPath).includes(p) && QuoteFormCommonPropertyFieldMap[p];

type CustomTransformer = {
  fieldName: string;
  propertyPath: string;
  transformer?: (errors: ApiError[]) => CustomFieldErrorValue;
};

const mapErrorsToField = (
  errors: ApiError[],
  customTransformers: CustomTransformer[] = [],
): Map<string, MapErrorValue[]> =>
  errors.reduce((acc, _) => {
    const customField = customTransformers.find(({ propertyPath }) =>
      _.field?.startsWith(propertyPath),
    );

    const fieldName = customField
      ? customField.fieldName
      : getFieldNameByPropertyPath(_.field as QuoteFormCommonPropertyPath) || FORM_ERROR;

    const value: MapErrorValue = customField ? _ : _.message;

    acc.set(fieldName, Array.from(new Set<MapErrorValue>(acc.get(fieldName) || []).add(value)));

    return acc;
  }, new Map<string, MapErrorValue[]>());

export const transformQuoteFormApiErrors = <CustomErrorsMap extends CustomFieldError>(
  apiErrors: ApiError[],
  customTransformers: CustomTransformer[] = [],
): QuoteFormCommonApiErrorsMap & Partial<CustomErrorsMap> => {
  const map = mapErrorsToField(apiErrors, customTransformers);

  const errors = [FORM_ERROR, ...Object.values(QuoteFormCommonFieldName)].reduce((acc, _) => {
    if (map.get(_)) return { ...acc, [_]: map.get(_) };
    return acc;
  }, {});

  const customErrors: Partial<CustomErrorsMap> = customTransformers.reduce(
    (acc, { fieldName, transformer }) => ({
      ...acc,
      [fieldName]: transformer
        ? transformer(map.get(fieldName) as ApiError[])
        : map?.get(fieldName)?.map((_: ApiError) => _.message),
    }),
    {},
  );

  return { ...errors, ...customErrors };
};
