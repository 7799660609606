import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

type FilterButtonProps = {
  filterLabel: string;
  onClick: (event: React.MouseEvent) => void;
  isOpen?: boolean;
  valueLabel?: string;
};

const FilterButton = ({ filterLabel, onClick, isOpen, valueLabel }: FilterButtonProps) => (
  <button
    className={joinClassNames(
      styles.filterButton,
      valueLabel && styles.isActive,
      isOpen && styles.isOpen,
    )}
    type="button"
    onClick={onClick}
  >
    {valueLabel ? (
      <>
        <span className={styles.subLabel}>{filterLabel}</span>
        {valueLabel}
      </>
    ) : (
      filterLabel
    )}
  </button>
);

const FilterDropdown = ({
  children,
  filterLabel,
  valueLabel,
}: {
  children: ReactNode;
  filterLabel: string;
  valueLabel?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (ref.current && target instanceof Node && !ref.current.contains(target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div className={styles.filterDropdown} ref={ref}>
      <FilterButton
        filterLabel={filterLabel}
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        valueLabel={valueLabel}
      />
      {isOpen && <div className={styles.contents}>{children}</div>}
    </div>
  );
};

export default FilterDropdown;
