import { useCallback } from 'react';
import { OperationVariables, useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';

function usePromiseQuery<TData, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query,
        variables,
      }),
    [query, client],
  );
}

export default usePromiseQuery;
