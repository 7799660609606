import React from 'react';
import { Icon, IconName } from '@estimateone/frontend-components';
import styles from './styles.scss';

const ReturnLink: React.VFC<{ url: string | null }> = ({ url }) => (
  <a className={styles.returnLink} href={url ?? undefined}>
    <Icon name={IconName.ChevronLeft} size="0.7em" /> Back
  </a>
);

export default ReturnLink;
