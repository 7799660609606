import React from 'react';

type IconProps = {
  icon?: string;
  extraClass?: string;
};

const Icon: React.VFC<IconProps> = ({ icon = 'user', extraClass = '' }) => (
  <i className={`icon icon-${icon} ${extraClass}`} aria-label={icon} />
);

export default Icon;
