/*
 * Modified version of https://github.com/final-form/final-form-focus.
 * Caters for global errors and async submit errors.
 */
import { Decorator, FormApi, getIn } from 'final-form';
import { getOffset } from './utils';
import {
  FocusableElement,
  FocusableInput,
  FocusOnErrorsDecoratorOptions,
  GetElements,
} from './types';

export type FormErrorsType = Record<string, unknown>;

export const findElement = <Errors extends FormErrorsType>(
  elements: FocusableElement[],
  errors: Errors,
): FocusableElement | undefined =>
  elements.find((element) => {
    const name = (element as FocusableInput).name || element.dataset.name;
    return name && getIn(errors, name);
  });

const getFormElements =
  (name: string): GetElements =>
  () => {
    if (document === undefined) return [];

    const form = document.querySelector(`form#${name}`);
    return form ? Array.prototype.slice.call(form.querySelectorAll('[name],[data-name]')) : [];
  };

const createDecorator =
  <FormValues, Errors extends FormErrorsType>(
    formId: string,
    options: FocusOnErrorsDecoratorOptions = {},
  ): Decorator<FormValues> =>
  (form: FormApi<FormValues>) => {
    const { customOffsets } = options;

    const focusOnFirstError = (errors: Errors) => {
      const getElements = getFormElements(formId);
      const firstElement = findElement(getElements(), errors);

      if (firstElement) {
        const elementName = (firstElement as FocusableInput).name || firstElement.dataset.name;
        const elementTop = firstElement.getBoundingClientRect().top;

        if (elementName) {
          window.scroll({
            top: elementTop + window.scrollY - getOffset(elementName, customOffsets),
            behavior: 'smooth',
          });
        }
      }
    };

    // eslint-disable-next-line fp/no-let
    let validationErrors: Errors = {} as Errors;
    const updateValidationErrors = (newErrors: Errors) => {
      // eslint-disable-next-line fp/no-mutation
      validationErrors = newErrors;
    };

    // Subscribe to changes in validation errors, and update the decorator's validationErrors value
    // (the decorator is only invoked as a whole once when the form is initialised so it will not
    // self update the form state).
    const unsubscribe = form.subscribe(
      (nextState) => {
        updateValidationErrors(nextState.errors as Errors);
      },
      { errors: true },
    );

    const afterSubmit = ({ errors, submitErrors }: { errors?: Errors; submitErrors?: Errors }) => {
      if (errors && Object.keys(errors).length) {
        focusOnFirstError(errors);
      } else if (submitErrors && Object.keys(submitErrors).length) {
        focusOnFirstError(submitErrors);
      }
    };

    const originalSubmit = form.submit;

    // Rewrite submit function.
    // eslint-disable-next-line fp/no-mutation,no-param-reassign
    form.submit = async () => {
      const result = await originalSubmit.call(form);
      afterSubmit({ errors: validationErrors, submitErrors: result });
      return result;
    };

    return () => {
      unsubscribe();
      // eslint-disable-next-line fp/no-mutation,no-param-reassign
      form.submit = originalSubmit;
    };
  };

export const focusOnErrorsDecorator = <FormValues, Errors extends FormErrorsType>(
  formId: string,
  options: FocusOnErrorsDecoratorOptions = {},
) => createDecorator<FormValues, Errors>(formId, options);
