import React, { useRef, MutableRefObject } from 'react';

type InputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (params: {
    name: string;
    value: string;
    node: MutableRefObject<HTMLInputElement | null>;
  }) => void;
  type?: string;
  label?: string;
  placeholder?: string;
  errorText?: string;
  onKeyUpEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
};

const Input = ({
  className,
  id,
  name,
  value,
  onChange,
  type = 'text',
  label,
  placeholder,
  errorText,
  onKeyUpEnter,
  onBlur,
  onFocus,
  autoFocus = false,
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event;

    if (keyCode === 13 && onKeyUpEnter) onKeyUpEnter(event);
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value: targetValue },
    } = event;

    onChange({
      name,
      value: targetValue,
      node: inputRef,
    });
  };

  return (
    <div className={`form-group ${className} ${errorText ? 'has-error' : ''}`}>
      {label && (
        <label className="control-label" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        ref={inputRef}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={handleKeyUp}
        className="form-control"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
      {errorText && <span className="help-block">{errorText}</span>}
    </div>
  );
};

export default Input;
