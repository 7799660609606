import React, { useState } from 'react';
import { Button, ButtonSize } from '@estimateone/frontend-components';
import { ItpDeleteQuoteModal } from '../ItpModals/ItpDeleteQuoteModal';
import { ItpReviseQuoteModal } from '../ItpModals/ItpReviseQuoteModal';
import { QuotesTable } from './QuotesTable';
import { ExtraFilesTable } from './QuotesTable/ExtraFilesTable';
import { sortQuotesByDate } from './utils';
import { ItpButtonStyleWrapper, ItpButtonType } from '@shared/Itp/ItpStyleWrapper';
import { ItpSubbieContextProps, useItpSubbieContext } from '../../../context';
import { useDeleteLatestQuote } from './hooks';
import styles from './styles.scss';

export const QuotesTables = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reviseModalOpen, setReviseModalOpen] = useState(false);
  const [itpSubbieContext, setItpSubbieContext] = useItpSubbieContext();

  const reviseQuoteClickHandler = () => {
    setItpSubbieContext(
      (prevData: ItpSubbieContextProps): ItpSubbieContextProps => ({
        ...prevData,
        renderTable: false,
      }),
    );
  };

  const handleReviseQuoteClicked = () => {
    setReviseModalOpen(true);
  };

  const handleReviseModalClosed = () => {
    setReviseModalOpen(false);
  };

  const handleDeleteClicked = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClosed = () => {
    setDeleteModalOpen(false);
  };

  const { submit: deleteLatestQuote } = useDeleteLatestQuote(itpSubbieContext.rfqId);

  const quotesForRfqSortedByCreatedAt = sortQuotesByDate(itpSubbieContext.quotesForRfq ?? []);

  const [mostRecentQuote, ...quoteHistory] = quotesForRfqSortedByCreatedAt;

  return (
    <>
      {mostRecentQuote && (
        <>
          <h4>Current Issued Quote</h4>
          <QuotesTable quotesForRfq={[quotesForRfqSortedByCreatedAt[0]]} />
          {mostRecentQuote.additionalFiles && mostRecentQuote.additionalFiles.length > 0 && (
            <ExtraFilesTable additionalFiles={mostRecentQuote.additionalFiles} />
          )}
        </>
      )}
      <div className={styles.flexRowContentStart}>
        <ItpButtonStyleWrapper buttonType={ItpButtonType.Primary}>
          <Button size={ButtonSize.Large} fullWidth onClick={handleReviseQuoteClicked}>
            Revise Quote
          </Button>
        </ItpButtonStyleWrapper>
        <ItpButtonStyleWrapper buttonType={ItpButtonType.RedOutline}>
          <Button size={ButtonSize.Large} fullWidth onClick={handleDeleteClicked}>
            Delete Quote
          </Button>
        </ItpButtonStyleWrapper>
      </div>

      {quoteHistory.length > 0 && (
        <>
          <h4>Quote History</h4>
          <QuotesTable quotesForRfq={quotesForRfqSortedByCreatedAt.slice(1)} />
        </>
      )}

      <ItpDeleteQuoteModal
        isOpen={deleteModalOpen}
        removeModal={handleDeleteModalClosed}
        handleDelete={deleteLatestQuote}
      />
      <ItpReviseQuoteModal
        isOpen={reviseModalOpen}
        removeModal={handleReviseModalClosed}
        handleRevise={reviseQuoteClickHandler}
      />
    </>
  );
};
