const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function readableFileSize(bytes: number) {
  const thresh = 1000;
  if (bytes < thresh) return `${bytes} B`;

  const [val, unit, u] = units.reduce(
    ([b, accUn, accI], un, i) => {
      if (Math.abs(b) < thresh) return [b, accUn, accI];
      return [b / 1000, un, i];
    },
    [bytes, 'B', 0],
  );

  const roundFactor = 10 ** Math.min(u, 2);
  return `${Math.round(val * roundFactor) / roundFactor} ${unit}`;
}
