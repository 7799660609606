import { gql } from '@apollo/client';
import Errors from '../../../../api/fragments/Errors';

export const DELETE_LATEST_QUOTES_FOR_RFQ = gql`
  mutation ItpDeleteLatestQuoteForRfq($input: DeleteLatestQuoteForRfqInput!) {
    deleteLatestQuoteForRfq(input: $input) {
      __typename
      ... on LimitedQuote {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
