export const ranges = (currency: string) => ({
  69: `Not Available`,
  99: `< ${currency}100k`,
  100000: `< ${currency}100k`,
  250000: `${currency}100k - ${currency}250k`,
  500000: `${currency}250k - ${currency}500k`,
  750000: `${currency}500k - ${currency}750k`,
  1000000: `${currency}750k - ${currency}1m`,
  1500000: `${currency}1m - ${currency}1.5m`,
  2000000: `${currency}1.5m - ${currency}2m`,
  3000000: `${currency}2m - ${currency}3m`,
  4000000: `${currency}3m - ${currency}4m`,
  5000000: `${currency}4m - ${currency}5m`,
  10000000: `${currency}5m - ${currency}10m`,
  15000000: `${currency}10m - ${currency}15m`,
  20000000: `${currency}15m - ${currency}20m`,
  30000000: `${currency}20m - ${currency}30m`,
  50000000: `${currency}30m - ${currency}50m`,
  100000000: `${currency}50m - ${currency}100m`,
  200000000: `${currency}100m - ${currency}200m`,
  200000001: `> ${currency}200m`,
});

export default ranges;
