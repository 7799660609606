import React from 'react';

const getDescription = (isQuoting: boolean | null) => {
  if (isQuoting === null) {
    return 'Not responded';
  }
  return isQuoting ? 'Quoting' : 'Not quoting';
};

const RfqResponse = ({ isQuoting }: { isQuoting: boolean | null }) => (
  <span className={`${isQuoting ? 'info' : 'danger'} white-space-nowrap`}>
    <i className={`icon icon-${isQuoting ? 'check icon-sm' : 'flag'} icon-left`} />
    {getDescription(isQuoting)}
  </span>
);

export default RfqResponse;
