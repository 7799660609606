import React, { useEffect } from 'react';
import { useTable, useSortBy, TableInstance, IdType, HeaderGroup } from 'react-table';
import { joinClassNames } from '@estimateone/frontend-components';
import SortIcon from './SortIcon';
import { SortDirection } from '../types';
import { CustomColumnFields, TableColumn } from './types';
import styles from './styles.scss';

export { CustomColumnFields, TableColumn };

const getSortDirection = (column: { isSorted: boolean; isSortedDesc?: boolean }) => {
  if (!column.isSorted) return undefined;
  return column.isSortedDesc ? SortDirection.DESC : SortDirection.ASC;
};

export type TableProps<TableRowContents extends Record<string, unknown>> = {
  columns: TableColumn<TableRowContents>[];
  data: TableRowContents[];
  sortColumn?: string;
  sortDir?: SortDirection;
  disableSortRemove?: boolean;
  onSortChange?: (id?: string, direction?: SortDirection) => void;
  loading?: boolean;
  hiddenColumns?: IdType<TableRowContents>[];
  manualSort?: boolean;
};

const Table = <TableRowContents extends Record<string, unknown>>({
  columns,
  data,
  sortColumn,
  sortDir,
  disableSortRemove = false,
  onSortChange,
  loading = false,
  hiddenColumns = [],
  manualSort = true,
}: TableProps<TableRowContents>) => {
  const sortDescending = sortDir === SortDirection.DESC;
  const {
    state: { sortBy: sortState },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<TableRowContents>(
    {
      columns,
      data,
      disableMultiSort: true,
      disableSortRemove,
      manualSortBy: manualSort,
      autoResetSortBy: false,
      initialState: {
        sortBy: sortColumn ? [{ id: sortColumn, desc: sortDescending }] : [],
        hiddenColumns,
      },
    },
    useSortBy,
  ) as TableInstance<TableRowContents>;

  useEffect(() => {
    if (onSortChange !== undefined) {
      if (sortState.length) {
        const [firstCol] = sortState;
        const { id, desc } = firstCol;
        if (id !== sortColumn || desc !== sortDescending) {
          onSortChange(id, desc ? SortDirection.DESC : SortDirection.ASC);
        }
      } else {
        onSortChange();
      }
    }
  }, [onSortChange, sortColumn, sortDescending, sortState]);

  return (
    <table
      {...getTableProps()}
      className={joinClassNames(
        styles.table,
        loading ? styles.resultsLoading : styles.resultsLoaded,
      )}
    >
      <thead>
        {headerGroups.map((headerGroup) => {
          const headerGroupProps = headerGroup.getHeaderGroupProps();
          return (
            <tr {...headerGroupProps} key={headerGroupProps.key ?? 'headers'}>
              {headerGroup.headers.map(
                (column: HeaderGroup<TableRowContents> & CustomColumnFields) => {
                  const sortDirection = getSortDirection(column);

                  // It'll show the up and down arrows side by side if no sort direction is set therefore need to set a bigger size.
                  const sortIconSize = sortDirection ? '1.5em' : '2em';
                  const columnHeaderProps = column.getHeaderProps(column.getSortByToggleProps());

                  return (
                    <th
                      className={column.fitToContent ? styles.fitToContent : undefined}
                      {...columnHeaderProps}
                      key={columnHeaderProps.key ?? column.id}
                    >
                      {column.render('Header')}
                      <SortIcon
                        sortDirection={sortDirection}
                        sortDisabled={column.disableSortBy}
                        size={sortIconSize}
                      />
                    </th>
                  );
                },
              )}
            </tr>
          );
        })}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            <tr {...rowProps} key={rowProps.key ?? row.id}>
              {row.cells.map((cell) => {
                const cellProps = cell.getCellProps();
                return (
                  <td {...cellProps} key={cellProps.key ?? `${cell.column.Header}-${cell.row.id}`}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
