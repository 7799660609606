import React, { ReactNode } from 'react';
import { ItpButtonType } from './enums';
import styles from './styles.scss';

type ItpButtonStyleWrapperProps = {
  children: ReactNode;
  buttonType: ItpButtonType;
};

export const ItpButtonStyleWrapper = (
  { children, buttonType }: ItpButtonStyleWrapperProps, // btn-itp-form-submit will override the style to customise itp page by content author.
) => <div className={`${styles[buttonType]} btn-itp-form-submit`}>{children}</div>;
