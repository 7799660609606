import React, { useContext } from 'react';
import { useForm } from 'react-final-form';
import { Col, Fieldset, HelpIcon, Legend, Row } from '@estimateone/frontend-components';
import { Heading } from '../../../../fec';
import { QuoteFile as QuoteFileCard } from '../../QuoteFiles';
import QuoteAmountField from './QuoteAmountField';
import QuoteDetailsField from './QuoteDetailsField';
import QuoteExtraFilesField from './QuoteExtraFilesField';
import QuoteFileField from './QuoteFileField';
import { useHasFixedPriceQuotesFeature } from '@shared';
import { FileManagerParams } from '@shared/FileUploader/types';
import { CheckboxField } from '@shared/FinalForm/Field';
import { HelpSliderTrigger } from '@shared/HelpSliderTrigger';
import { TwigGlobalContext } from '@builder/context/TwigFeatureProvider/TwigGlobalProvider';
import { useIsItp } from '@itp-subbie/context';
import { QuoteFormCommonFieldName } from '@ascension/enums';
import type { ExtraFilesServerlessFileManagerParams, QuoteFormsValues } from '../types';
import { DownloadableQuoteFile } from '@api/fragments/Quotes/types';
import styles from '../styles.scss';

const useIsBuilder = () =>
  useContext(TwigGlobalContext)?.getBooleanValue('isBuilder', false) ?? false;

type QuoteFormQuoteSectionProps = Pick<FileManagerParams, 'context' | 'fileManagerLocale'> &
  ExtraFilesServerlessFileManagerParams & {
    quoteFileField: React.ReactElement<QuoteFileField> | React.ReactElement<typeof QuoteFileCard>;
  };

const QuoteFormQuoteSection = ({
  context,
  fileManagerLocale,
  extraFilesParentType,
  extraFilesParentId,
  quoteFileField,
}: QuoteFormQuoteSectionProps) => {
  const { getState } = useForm<QuoteFormsValues>();
  const { values } = getState();
  const hasFixedPriceQuotesFeature = useHasFixedPriceQuotesFeature();
  const isItp = useIsItp();
  const isBuilder = useIsBuilder();

  return (
    <>
      <div className={styles.sectionHeading}>
        <Heading level={2}>Your quote</Heading>
      </div>

      <Row>
        <Col span={12}>
          <QuoteAmountField />
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <div className={styles.section}>{quoteFileField}</div>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <div
            className={
              values[QuoteFormCommonFieldName.ExtraFiles]?.length ? styles.section : undefined
            }
          >
            <QuoteExtraFilesField
              context={context}
              fileManagerLocale={fileManagerLocale}
              parentType={extraFilesParentType}
              parentId={extraFilesParentId}
            />
          </div>
        </Col>
      </Row>

      {hasFixedPriceQuotesFeature ? (
        <Row>
          <Col span={12}>
            <Fieldset>
              <Legend>
                Fixed quote confirmation (optional)
                {isItp ? (
                  false
                ) : (
                  <HelpSliderTrigger
                    template={isBuilder ? 'fixed_price_help_builder' : 'fixed_price_help_subbie'}
                    className={styles.helpLink}
                  >
                    <HelpIcon />
                  </HelpSliderTrigger>
                )}
              </Legend>
              <CheckboxField
                label="I confirm that the quote provided is fixed and remains valid for the specified validity period stated in the quote."
                id={QuoteFormCommonFieldName.ConfirmFixedPrice}
                fieldName={QuoteFormCommonFieldName.ConfirmFixedPrice}
              />
            </Fieldset>
          </Col>
        </Row>
      ) : (
        false
      )}

      <Row>
        <Col span={12}>
          <QuoteDetailsField />
        </Col>
      </Row>
    </>
  );
};

type QuoteSectionWithExistingQuoteFileProps = Pick<
  FileManagerParams,
  'context' | 'fileManagerLocale'
> &
  ExtraFilesServerlessFileManagerParams & { quoteFile: DownloadableQuoteFile };

const QuoteSectionWithExistingQuoteFile = ({
  quoteFile,
  ...sectionProps
}: QuoteSectionWithExistingQuoteFileProps) => (
  <QuoteFormQuoteSection quoteFileField={<QuoteFileCard file={quoteFile} />} {...sectionProps} />
);

type QuoteSectionWithQuoteFileUploaderProps = FileManagerParams &
  ExtraFilesServerlessFileManagerParams;

const QuoteSectionWithQuoteFileUploader = ({
  context,
  fileManagerLocale,
  parentType,
  parentId,
  extraFilesParentType,
  extraFilesParentId,
}: QuoteSectionWithQuoteFileUploaderProps) => (
  <QuoteFormQuoteSection
    context={context}
    fileManagerLocale={fileManagerLocale}
    extraFilesParentType={extraFilesParentType}
    extraFilesParentId={extraFilesParentId}
    quoteFileField={
      <QuoteFileField
        context={context}
        fileManagerLocale={fileManagerLocale}
        parentType={parentType}
        parentId={parentId}
      />
    }
  />
);

export type QuoteFileField = typeof QuoteFileField;

export {
  QuoteAmountField,
  QuoteDetailsField,
  QuoteExtraFilesField,
  QuoteSectionWithExistingQuoteFile,
  QuoteSectionWithQuoteFileUploader,
};
