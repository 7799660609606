import React, { useState, useEffect } from 'react';
import { Checkbox as E1Checkbox, CheckboxStatus } from '@estimateone/frontend-components';
import styles from './Checkbox.scss';

type CheckboxProps = {
  id: string;
  name: string;
  isChecked?: boolean;
  onChange: (params: { name: string; isChecked: boolean }) => void;
  label?: string;
  hasError?: boolean;
  readOnly?: boolean;
  inline?: boolean;
};

const Checkbox = ({
  id,
  name,
  isChecked = false,
  onChange,
  label,
  hasError = false,
  readOnly = false,
  inline = false,
}: CheckboxProps) => {
  const [status, setStatus] = useState<CheckboxStatus>(
    isChecked ? CheckboxStatus.Checked : CheckboxStatus.Unchecked,
  );

  useEffect(() => {
    setStatus(isChecked ? CheckboxStatus.Checked : CheckboxStatus.Unchecked);
  }, [isChecked]);

  return (
    <div className={styles.checkboxContainer}>
      <E1Checkbox
        id={id}
        name={name}
        statusCurrent={status}
        onClick={() => {
          const isChecking = status === CheckboxStatus.Unchecked;
          setStatus(isChecking ? CheckboxStatus.Checked : CheckboxStatus.Unchecked);
          onChange({ name, isChecked: isChecking });
        }}
        label={label ?? ''}
        hasError={hasError}
        disabled={readOnly}
        hideLabel={inline}
      />
    </div>
  );
};

export default Checkbox;
