import React, { ReactNode } from 'react';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import styles from './styles.scss';

// This is a legacy slider only for use with the vcard_slider from jQuery

const LegacySliderFooter: React.VFC<{ acceptFunction: () => void; actionText: string }> = ({
  acceptFunction,
  actionText,
}) => (
  <section className={`${styles.sliderFooter} super-slider-footer`}>
    <div>
      <div className="button-wrapper">
        <Button
          type="submit"
          variant={ButtonVariant.Primary}
          onClick={acceptFunction}
          className="pull-right"
        >
          <span className="text-bold">{actionText}</span>
        </Button>
      </div>
    </div>
  </section>
);

const LegacySlider = ({
  children,
  title,
  showFooter = true,
  acceptFunction,
  actionText,
}: {
  children: ReactNode;
  title: string;
  showFooter?: boolean;
  acceptFunction: () => void;
  actionText: string;
}) => (
  <div className={`super-slider-content ${styles.reactSlider}`}>
    <header className="super-slider-header">
      <h2>{title}</h2>
    </header>
    <section className={styles.reactSliderBody}>{children}</section>
    {showFooter && <LegacySliderFooter acceptFunction={acceptFunction} actionText={actionText} />}
  </div>
);

export default LegacySlider;
