import React, { ReactNode } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import { SortDirection } from '../types';
import { EntityId } from '@ascension/types';

export type Column<T> = {
  key: string;
  name: string;
  sortable: boolean;
  func?: (val: unknown, row: T) => string | JSX.Element;
};

export type ColumnConfig<T> = readonly Column<T>[];

type TableHeaderWrapperProps = {
  className?: string;
  onClick?: (key: string) => void;
  text?: string;
  columnKey: string;
};

const TableHeaderWrapper = ({ className, onClick, text, columnKey }: TableHeaderWrapperProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick(columnKey);
    }
  };

  return (
    <th className={`sort_col_${columnKey} ${className}`} onClick={handleClick}>
      {text}
    </th>
  );
};

export type SortableTableRow = Record<string, unknown> & { id: EntityId };

type SortableTableProps<T> = {
  rows: T[];
  columnConfig: ColumnConfig<T>;
  sortBy: {
    column?: keyof T;
    direction: SortDirection;
    fnSortData?: (col: keyof T, dir: SortDirection) => void;
  };
  extraClasses?: string;
};

const SortableTable = <T extends SortableTableRow>({
  rows,
  columnConfig,
  sortBy,
  extraClasses,
}: SortableTableProps<T>) => {
  const reverseDirection = (dir: SortDirection) =>
    dir === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;

  const sort = (col: string) => {
    if (sortBy.fnSortData) {
      const dir = col === sortBy.column ? reverseDirection(sortBy.direction) : SortDirection.ASC;
      sortBy.fnSortData(col, dir);
    }
  };

  const calcSortClass = (col: string) =>
    col === sortBy.column
      ? sortBy.direction === SortDirection.ASC
        ? 'sorting_asc'
        : 'sorting_desc'
      : 'sorting';

  return (
    <table className={joinClassNames('table dataTable', extraClasses)}>
      <thead>
        <tr>
          {columnConfig.map((column) => (
            <TableHeaderWrapper
              key={column.key}
              className={column.sortable ? calcSortClass(column.key) : undefined}
              onClick={column.sortable ? sort : undefined}
              text={column.name}
              columnKey={column.key}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={row.id} role="row" className={index % 2 === 0 ? 'odd' : 'even'}>
            {columnConfig.map((column) => (
              <td key={column.key}>
                {typeof column.func === 'function'
                  ? column.func(row[column.key], row)
                  : (row[column.key] as ReactNode)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SortableTable;
