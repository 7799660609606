import { gql } from '@apollo/client';

const ITP_RFQ_CONTEXT_QUERY = gql`
  query ItpRfqContextQuery($rfqId: EntityId!) {
    currentItpUser {
      id
      email
      fullName

      account {
        id
        name
      }
    }

    quotesForRfq(rfqId: $rfqId) {
      id
      createdAt
      title
      fileName
      fileSize
      confirmFixedPrice
      additionalFiles {
        id
        fileName
        fileSize
        hash
      }
    }
  }
`;

export { ITP_RFQ_CONTEXT_QUERY };
