import React from 'react';
import { useField } from 'react-final-form';
import { CurrencyInput, CurrencyInputProps } from '@estimateone/frontend-components';
import { FieldWrapper } from './FieldWrapper';

export type CurrencyFieldProps = {
  fieldName: string;
  id: string;
  label: string;
  currencySymbol?: string;
} & Partial<Omit<CurrencyInputProps, 'prefix'>>;

export const CurrencyField: React.VFC<CurrencyFieldProps> = ({
  fieldName,
  id,
  label,
  currencySymbol,
  ...props
}) => {
  const {
    input: { value, ...rest },
    meta: { error, submitError, touched },
  } = useField(fieldName);

  return (
    <FieldWrapper errors={error || submitError} showErrors={touched}>
      <CurrencyInput
        id={id}
        label={label}
        amount={value}
        prefix={currencySymbol}
        {...rest}
        {...props}
      />
    </FieldWrapper>
  );
};
