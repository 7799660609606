import React from 'react';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import { ItpButtonStyleWrapper, ItpButtonType } from '../../../../shared/Itp/ItpStyleWrapper';
import styles from './styles.scss';

type ItpModalButtonsProps = {
  submitBtnTitle: string;
  submitOnClick: () => void;
  cancelOnClick: () => void;
};

export const ItpModalButtons: React.VFC<ItpModalButtonsProps> = ({
  submitBtnTitle,
  submitOnClick,
  cancelOnClick,
}) => (
  <div className={styles.flexRowContentStart}>
    <ItpButtonStyleWrapper buttonType={ItpButtonType.BlackOutline}>
      <Button onClick={cancelOnClick}>Cancel</Button>
    </ItpButtonStyleWrapper>
    <ItpButtonStyleWrapper buttonType={ItpButtonType.Warning}>
      <Button variant={ButtonVariant.Red} onClick={submitOnClick}>
        {submitBtnTitle}
      </Button>
    </ItpButtonStyleWrapper>
  </div>
);
