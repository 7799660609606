import { gql } from '@apollo/client';
import Errors from '../../../../api/fragments/Errors';

export const CREATE_OR_REVISE_QUOTE_FOR_RFQ = gql`
  mutation ItpCreateOrReviseQuoteForRfq($input: CreateOrReviseQuoteForRfqInput!) {
    createOrReviseQuoteForRfq(input: $input) {
      __typename
      ... on LimitedQuote {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
