import React from 'react';
import { useField } from 'react-final-form';
import { TextArea, TextAreaProps } from '@estimateone/frontend-components';
import { FieldWrapper } from './FieldWrapper';

export type TextAreaFieldProps = {
  fieldName: string;
  id: string;
  label: string;
  validate?: (value: string) => string[] | undefined;
  inlineValidate?: boolean;
} & Partial<TextAreaProps>;

export const TextAreaField = ({
  fieldName,
  id,
  label,
  validate,
  inlineValidate = false,
  ...props
}: TextAreaFieldProps) => {
  const {
    input,
    meta: { error, submitError, touched, pristine },
  } = useField(fieldName, { validate });

  return (
    <FieldWrapper
      errors={error || submitError}
      showErrors={touched || (inlineValidate && !pristine)}
    >
      <TextArea id={id} label={label} {...input} {...props} />
    </FieldWrapper>
  );
};
