import $ from 'jquery';
import Routing from 'routing';
import E1Request from './E1Request';

/* eslint-disable camelcase */
type HelpResponse = {
  success: boolean;
  helper_string: string;
};

type ArticleResult = { title: string; url: string; preview: string };

type SearchTerm = string;

type SearchResponse = {
  success: boolean;
  search_term: string;
  article_results: ArticleResult[];
};
/* eslint-enable */

const LOADING_CLASS = 'loading';
const SEARCHING_CLASS = 'searching';
const ACTIVE_CLASS = 'helper-active';

export default class Helper {
  private $container: JQuery;
  private $helper: JQuery;
  private url: string | null;
  private request: E1Request<HelpResponse> | E1Request<SearchResponse> | null;
  private searchTerm: SearchTerm | null;
  private readonly results: Map<SearchTerm, ArticleResult[]>;
  private autoCompleteTimer: number | null;
  private $searchInput: JQuery;
  private $contentContainer: JQuery;
  private $searchResultContainer: JQuery;
  private $searchResultDiv: JQuery;
  private $emptySearchResultDiv: JQuery;

  constructor(private $target: JQuery) {
    this.$container = $('body');
    this.$helper = $target;
    this.url = null;
    this.request = null;
    this.searchTerm = null;
    this.results = new Map<string, ArticleResult[]>();
    this.autoCompleteTimer = null;
    this.$searchInput = $target.find('.help-search');
    this.$contentContainer = $target.find('.helper-content');
    this.$searchResultContainer = $target.find('.help-search-results-container');
    this.$searchResultDiv = $target.find('.help-search-results');
    this.$emptySearchResultDiv = $target.find('.no-results');

    this.init();
  }

  init() {
    this.$container = $('body');

    this.$helper.on('click', '.helper-dismiss', () => {
      this.close();
    });

    this.$searchInput.on('keyup', ({ which }) => {
      if ((which < 48 && which !== 8 && which !== 46 && which !== 32) || which >= 91) {
        return;
      }
      const searchTerm = this.$searchInput.val()?.toString() ?? '';
      if (searchTerm.length) {
        if (this.autoCompleteTimer) {
          clearTimeout(this.autoCompleteTimer);
          this.autoCompleteTimer = null;
        }

        this.autoCompleteTimer = window.setTimeout(() => {
          this.autoCompleteTimer = null;
          return this.search(searchTerm);
        }, 400);
      } else {
        this.toggleLoading(false);
        this.toggleSearching(false);
      }
    });
  }

  public show(): Helper {
    this.$container.addClass(ACTIVE_CLASS);
    this.$helper.find('input').first().trigger('focus');

    return this;
  }

  public setUrl(url: string): Helper {
    this.url = url;

    return this;
  }

  public async submit() {
    if (this.request && this.request.isPending()) {
      this.request.abort();
    }

    if (this.url) {
      this.request = new E1Request<HelpResponse>(this.url);

      this.toggleLoading(true);
      this.handleAjaxResponse(await this.request.submit());
    }
  }

  private getResultsForSearchTerm(term?: SearchTerm): ArticleResult[] {
    return this.results.get(term ?? this.searchTerm ?? '') || [];
  }

  private displayContent(content: string) {
    this.$contentContainer.html(content);
  }

  private close() {
    this.$container.removeClass(ACTIVE_CLASS);
  }

  private toggleSearching(showSearching: boolean) {
    this.$helper.toggleClass(SEARCHING_CLASS, showSearching);
  }

  private toggleLoading(showLoading: boolean) {
    this.$helper.toggleClass(LOADING_CLASS, showLoading);
  }

  private drawSearchResults() {
    const results = this.getResultsForSearchTerm();

    if (!results.length) {
      this.$searchResultDiv.html('');
      this.$emptySearchResultDiv.show();
      return;
    }

    const $resultsList = $('<ul>');
    $resultsList.append(
      ...results.map(({ url, title, preview }) => {
        const $li = $('<li>');
        const $title = $('<a>')
          .addClass('help-search-result-topic')
          .attr('href', url)
          .attr('target', '_uv')
          .text(title);

        const $desc = $('<p>').text(preview);

        return $li.append($title).append($desc);
      }),
    );

    this.$searchResultDiv.html($resultsList.html());
    this.$emptySearchResultDiv.hide();
  }

  private handleAjaxResponse(response: SearchResponse | HelpResponse) {
    this.toggleLoading(false);
    if ('helper_string' in response) {
      this.displayContent(response.helper_string);
    }
    if ('article_results' in response) {
      this.handleSearchResponse(response);
      this.$searchResultContainer.show();
    }
  }

  private handleSearchResponse({ article_results: articleResults }: SearchResponse) {
    this.results.set(this.searchTerm ?? '', articleResults);
    this.drawSearchResults();
  }

  private clearSearchResults(): void {
    this.$searchResultDiv.html('');
  }

  private async search(param: SearchTerm) {
    if (this.request && this.request.isPending()) {
      this.request.abort();
    }

    this.request = new E1Request<SearchResponse>(
      Routing.generate('app_help_search', {
        q: param,
      }),
    );

    this.toggleLoading(true);
    this.clearSearchResults();
    this.toggleSearching(true);
    this.$emptySearchResultDiv.hide();
    this.searchTerm = param;

    // Check the cache first
    if (this.results.has(param)) {
      this.drawSearchResults();
      this.$searchResultContainer.show();
      this.toggleLoading(false);
    } else {
      this.handleAjaxResponse(await this.request.submit());
    }
  }
}
