import React, { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { PossibleTypesMap } from '@apollo/client/cache';
import { createGatewayClient } from './createGatewayClient';

export const AscensionSupplierInsightsGatewayProvider = ({
  children,
  gatewayBaseUri,
  idpAuthoriseUri,
  applicationBaseUri,
  fragment,
}: {
  children: ReactNode;
  gatewayBaseUri: string;
  idpAuthoriseUri: string;
  applicationBaseUri: string;
  fragment?: { possibleTypes: PossibleTypesMap };
}) => (
  <ApolloProvider
    client={createGatewayClient(gatewayBaseUri, idpAuthoriseUri, applicationBaseUri, fragment)}
  >
    {children}
  </ApolloProvider>
);
