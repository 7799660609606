import { QuoteFormCommonFieldError, QuoteFormCommonFieldName } from '@ascension/enums';
import { FieldError } from '../../FinalForm/types';
import {
  QuoteAmountValue,
  QuoteFileValue,
  QuoteFormCommonFieldErrors,
  QuoteFormCommonFieldValues,
} from './types';
import { valueOf } from '@ascension/types';

export const validateQuoteAmount = (
  value: QuoteAmountValue | undefined,
): QuoteFormCommonFieldError[] | null =>
  !value && value !== 0 ? [QuoteFormCommonFieldError.AmountEmpty] : null;

export const validateQuoteFile = (
  value: QuoteFileValue | undefined,
): QuoteFormCommonFieldError[] | null =>
  !value ? [QuoteFormCommonFieldError.QuoteFileEmpty] : null;

export const validateQuoteFormCommonFields = (
  values: QuoteFormCommonFieldValues,
): QuoteFormCommonFieldErrors => {
  const errors = new Map<QuoteFormCommonFieldName, FieldError[] | null>();

  const validateSetErrorsIfExists = (
    field: QuoteFormCommonFieldName,
    validatorFunction: (value: valueOf<QuoteFormCommonFieldValues>) => FieldError[] | null,
  ) => {
    const fieldErrors = validatorFunction(values[field] as valueOf<QuoteFormCommonFieldValues>);
    if (fieldErrors) errors.set(field, fieldErrors);
  };

  validateSetErrorsIfExists(QuoteFormCommonFieldName.Amount, validateQuoteAmount);
  validateSetErrorsIfExists(QuoteFormCommonFieldName.QuoteFile, validateQuoteFile);

  return Array.from(errors.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
