import React from 'react';
import { Pricing, PricingVariant } from '../../../fec';
import { getBillingCycleString } from './util';
import styles from './styles.scss';

const ProductCardHeader: React.VFC<{ billingCycle: number; name: string; priceExGST: number }> = ({
  billingCycle,
  name,
  priceExGST,
}) => (
  <div className={styles.productCardHeader}>
    <strong className={styles.productName}>{name}</strong>

    <span className={styles.pricing}>
      <Pricing price={priceExGST} variant={PricingVariant.Featured} />
      <div className={styles.pricingDesc}>{getBillingCycleString(billingCycle)} ex. GST</div>
    </span>
  </div>
);

export default ProductCardHeader;
