import React from 'react';
import Link, { LinkProps } from './Link';
import UserActivity, { UserActivityProps } from './UserActivity';

const UserActivityLink: React.VFC<LinkProps & UserActivityProps> = ({
  href,
  onClick,
  onClickParams,
  amount,
  showAmount = true,
  description,
}) => (
  <Link
    onClick={onClick}
    href={href}
    onClickParams={onClickParams}
    extraClasses={`${amount > 0 ? 'danger' : 'success'}`}
  >
    <UserActivity amount={amount} showAmount={showAmount} description={description} />
  </Link>
);

export default UserActivityLink;
