import React, { ReactNode } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
  HttpLink,
} from '@apollo/client';
import { InMemoryCache, PossibleTypesMap } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import * as adminFragment from '../fragments-admin.json';
import TokenCache from './TokenCache';
import { isServerError } from './util';

export const createGatewayClient = (
  gatewayBaseUri: string,
  idpAuthoriseUri: string,
  applicationBaseUri: string,
  fragment?: { possibleTypes: PossibleTypesMap },
): ApolloClient<NormalizedCacheObject> => {
  const tokenCache = new TokenCache(idpAuthoriseUri);

  const authLink = setContext(async (_, { headers }) => ({
    // return the headers to the context so httpLink can read them
    headers: {
      authorization: `Bearer ${await tokenCache.getToken()}`,
      ...headers,
    },
  }));

  const resetTokenOnErrorLink = onError(({ networkError }) => {
    if (networkError && isServerError(networkError) && networkError.statusCode === 401) {
      tokenCache.expireToken();
    }
  });

  // This will retry both getting the token and the GraphQL request 5 times with backoff
  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#default-configuration
  const retryLink = new RetryLink();

  const ascensionLink = new HttpLink({
    uri: `${gatewayBaseUri}${applicationBaseUri}`,
  });

  const { possibleTypes } = fragment || adminFragment;

  return new ApolloClient({
    cache: new InMemoryCache({ possibleTypes }),
    link: ApolloLink.from([retryLink, authLink, resetTokenOnErrorLink, ascensionLink]),
  });
};

const GatewayProvider = ({
  children,
  gatewayBaseUri,
  idpAuthoriseUri,
  applicationBaseUri,
  fragment,
}: {
  children: ReactNode;
  gatewayBaseUri: string;
  idpAuthoriseUri: string;
  applicationBaseUri: string;
  fragment?: { possibleTypes: PossibleTypesMap };
}) => (
  <ApolloProvider
    client={createGatewayClient(gatewayBaseUri, idpAuthoriseUri, applicationBaseUri, fragment)}
  >
    {children}
  </ApolloProvider>
);

export default GatewayProvider;
