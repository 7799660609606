export type SupportDetails = {
  phone: string;
  email: string;
  website: string;
};

const DEFAULT_SUPPORT_DETAILS: SupportDetails = {
  phone: '1300 705 035',
  email: 'support@estimateone.com',
  // TODO: Update this to new knowledge base
  website: 'http://support.estimateone.com',
};

const getSupportDetails = (): SupportDetails => window?.global?.support || DEFAULT_SUPPORT_DETAILS;

export default getSupportDetails;
