import { ProjectStatus } from '@ascension/_gqltypes/subbie.generated';

const PROJECT_OPEN = 1;
const PROJECT_AWARDED = 2;
const PROJECT_CLOSED = 3;

const GRAPHQL_ENUM_VALUES: {
  [ProjectStatus.OPEN]: 1;
  [ProjectStatus.AWARDED]: 2;
  [ProjectStatus.CLOSED]: 3;
} = {
  [ProjectStatus.OPEN]: PROJECT_OPEN,
  [ProjectStatus.AWARDED]: PROJECT_AWARDED,
  [ProjectStatus.CLOSED]: PROJECT_CLOSED,
};

export { PROJECT_OPEN, PROJECT_AWARDED, PROJECT_CLOSED, GRAPHQL_ENUM_VALUES };
