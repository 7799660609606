import React from 'react';
import { joinClassNames } from '@estimateone/frontend-components';

const UserInitialsIcon: React.VFC<{ initials?: string; isCurrentUser?: boolean }> = ({
  initials = '',
  isCurrentUser = false,
}) => (
  <div
    className={joinClassNames(
      'badge badge-round-sm badge-initials',
      isCurrentUser && 'current-user',
    )}
  >
    {initials}
  </div>
);

export default UserInitialsIcon;
