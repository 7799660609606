import React from 'react';
import { TextAreaField } from '@shared/FinalForm/Field';
import { QuoteFormCommonFieldName } from '@ascension/enums';

const QuoteDetailsField = () => (
  <TextAreaField
    fieldName={QuoteFormCommonFieldName.Details}
    id="quote-details"
    label="Quote message (optional)"
    rows={6}
    placeholder="Add any information to help clarify your quote, inclusions and exclusions, etc."
  />
);

export default QuoteDetailsField;
