import { useEffect, useState } from 'react';
import Routing, { RouterOptions } from 'routing';
import E1Request from '@ascension/js/classes/E1Request';

type NamedRoute = string;
type Method = 'GET' | 'POST';

function useE1Request<T extends { success: boolean }>(
  route: NamedRoute,
  params: RouterOptions,
  method: Method = 'GET',
  data: Record<string, unknown> = {},
) {
  const [called, setCalled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [response, setResponse] = useState<T | null>(null);

  useEffect(() => {
    const request = new E1Request<T, Record<string, unknown>>(
      Routing.generate(route, params),
      method,
      data,
    );

    if (!called) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        setCalled(true);
        setResponse(await request.submit());
        setLoading(false);
      })();
    }
    return () => {
      if (called) request.abort();
    };
  }, [called, method, params, data, route, setCalled, setLoading, setResponse]);

  return { loading, data: response };
}

export default useE1Request;
