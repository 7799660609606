import React from 'react';
import { truncate } from '@ascension/js/utils/truncate';

type TruncatedTextProps = typeof TruncatedText.defaultProps & {
  text: string;
  limit?: number;
};

class TruncatedText extends React.Component<TruncatedTextProps, { text: string }> {
  static defaultProps = {
    limit: 45,
  };

  constructor(props: Readonly<{ text: string; limit: number }>) {
    super(props);
    const { text, limit } = this.props;

    this.state = {
      text: this.truncateMessage(text, limit),
    };
  }

  truncateMessage = (message: string, limit: number) =>
    message.length > limit ? truncate(message, limit) : message;

  render() {
    const { text: title } = this.props;
    const { text } = this.state;

    return <span title={title}>{text}</span>;
  }
}

export default TruncatedText;
