import React, { useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  CardVariant,
  E1Link,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import CollapsibleProductInfo from './CollapsibleProductInfo';
import LicenseCard from './LicenseCard';
import ProductCardHeader from './ProductCardHeader';
import styles from './styles.scss';

type Product = {
  id: number;
  name: string;
  priceExGST: number;
  shortDescription: string | null;
  longDescription?: string | null;
  billingCycle: number;
};

type ProductCardProps = {
  isActive: boolean;
  onClickAdd: React.MouseEventHandler<HTMLButtonElement>;
  onClickRemove?: React.MouseEventHandler<HTMLButtonElement>;
  product: Product;
  isPurchased: boolean;
};

const ProductCard: React.VFC<ProductCardProps> = ({
  isActive,
  onClickAdd,
  product,
  isPurchased = false,
  onClickRemove,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const { billingCycle, name, priceExGST, shortDescription, longDescription } = product;

  return (
    <div className={styles.productCard}>
      <Card variant={CardVariant.Blue}>
        <Card.Body isWhite={!(isPurchased || isActive)}>
          <ProductCardHeader billingCycle={billingCycle} name={name} priceExGST={priceExGST} />
          <div className={styles.contents}>
            <div className={styles.summaryContainer}>
              <span>{shortDescription}</span>
            </div>

            <div className={styles.actions}>
              <span className={styles.infoToggle}>
                {longDescription && (
                  <E1Link onClick={toggleCollapse}>
                    {isCollapsed ? 'More info' : 'Less info'}
                  </E1Link>
                )}
              </span>

              {isPurchased ? (
                <Button size={ButtonSize.Small} onClick={onClickRemove} isDisabled>
                  Purchased
                </Button>
              ) : isActive ? (
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Grey}
                  onClick={onClickRemove}
                >
                  <Icon name={IconName.Clear} marginRight="small" /> Remove from your account
                </Button>
              ) : (
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Secondary}
                  onClick={onClickAdd}
                >
                  <Icon name={IconName.Add} marginRight="small" /> Add to your account
                </Button>
              )}
            </div>

            {longDescription && (
              <CollapsibleProductInfo longDescription={longDescription} isCollapsed={isCollapsed} />
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export { LicenseCard };

export default ProductCard;
