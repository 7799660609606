import React from 'react';
import { useField } from 'react-final-form';
import { SingleSelect, SingleSelectProps } from '../../../Select';
import { FieldWrapper } from '../FieldWrapper';
import styles from '../styles.scss';

/**
 * @deprecated
 */
export type SingleSelectFieldProps<T> = SingleSelectProps<T> & {
  fieldName: string;
  id: string;
  label: string;
  hideErrors?: boolean;
};

/**
 * @deprecated
 * @see /shared/FinalForm/Field/SingleSelectField.tsx
 */
export const SingleSelectField = <T,>({
  fieldName,
  id,
  label,
  hideErrors = false,
  ...props
}: SingleSelectFieldProps<T>) => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField(fieldName);

  return (
    <FieldWrapper errors={error || submitError} showErrors={!hideErrors && touched}>
      <div className={styles.selectContainer} data-name={fieldName}>
        <SingleSelect id={id} label={label} {...input} {...props} />
      </div>
    </FieldWrapper>
  );
};
