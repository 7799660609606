import { useMutation } from '@apollo/client';
import { DELETE_LATEST_QUOTES_FOR_RFQ } from './mutations';
import { EntityId } from '../../../../../types';
import {
  ItpDeleteLatestQuoteForRfq,
  ItpDeleteLatestQuoteForRfqVariables,
} from './types/ItpDeleteLatestQuoteForRfq';

export const useDeleteLatestQuote = (rfqId: EntityId) => {
  const [deleteLatestQuote] = useMutation<
    ItpDeleteLatestQuoteForRfq,
    ItpDeleteLatestQuoteForRfqVariables
  >(DELETE_LATEST_QUOTES_FOR_RFQ, {
    onCompleted: (data) => {
      if (data.deleteLatestQuoteForRfq.__typename === 'LimitedQuote') {
        // After mutation is successful, we will reload the page.
        location.reload();
      }
    },
  });

  const submit = async () => {
    const { data: response } = await deleteLatestQuote({ variables: { input: { rfqId } } });
    if (response?.deleteLatestQuoteForRfq.__typename === 'Errors') {
      return response.deleteLatestQuoteForRfq.errors;
    }
    return [];
  };

  return { submit };
};
