import React from 'react';
import md5 from 'md5';

const sizesByName = {
  tiny: 20,
  small: 40,
  medium: 80,
  large: 160,
};

const Gravatar: React.VFC<{
  name: string;
  email: string;
  size: keyof typeof sizesByName;
  round?: boolean;
}> = ({ name, email, size, round = false }) => {
  const sizePixels = sizesByName[size];
  return (
    <img
      alt={name}
      style={round ? { borderRadius: `${sizePixels / 2}px` } : {}}
      src={`https://secure.gravatar.com/avatar/${md5(
        email.toLowerCase(),
      )}?s=${sizePixels}&amp;r=g&amp;d=mm`}
    />
  );
};

export default Gravatar;
