import React, { useMemo } from 'react';
import { Table, TableColumn } from '@estimateone/frontend-components';
import { formatDatetimeToDate } from '@ascension/js/utils/datetime';
import { readableFileSize } from '@ascension/js/utils/filesize';
import { useHasFixedPriceQuotesFeature } from '@shared';
import readableFileName from '@shared/Quotes/utils/readableFileName';
import readableFileType from '@shared/Quotes/utils/readableFileType';
import { ItpRfqContextQuery_quotesForRfq as LimitedQuote } from '../../../../context/types/ItpRfqContextQuery';
import styles from './styles.scss';

type QuotesTableProps = {
  quotesForRfq: LimitedQuote[];
};

export const QuotesTable = ({ quotesForRfq }: QuotesTableProps) => {
  const hasFixedPriceQuotesFeature = useHasFixedPriceQuotesFeature();
  const columns: TableColumn<LimitedQuote>[] = useMemo(() => {
    const cols: TableColumn<LimitedQuote>[] = [
      {
        Header: 'Date',
        accessor: ({ createdAt }: LimitedQuote) => formatDatetimeToDate(createdAt),
        disableSortBy: true,
      },
      {
        Header: 'Type',
        accessor: ({ fileName }: LimitedQuote) => readableFileType(fileName),
        disableSortBy: true,
      },
      {
        Header: 'Size',
        accessor: ({ fileSize }: LimitedQuote) => readableFileSize(fileSize),
        disableSortBy: true,
      },
      {
        Header: 'Title',
        accessor: ({ fileName }: LimitedQuote) => readableFileName(fileName),
        disableSortBy: true,
      },
    ];

    if (hasFixedPriceQuotesFeature) {
      cols.push({
        Header: 'Fixed quote',
        accessor: ({ confirmFixedPrice }: LimitedQuote) => (confirmFixedPrice ? 'Yes' : 'No'),
        disableSortBy: true,
        fitToContent: true,
      });
    }

    return cols;
  }, [hasFixedPriceQuotesFeature]);

  return (
    <div className={styles.quotesTable}>
      <Table columns={columns} data={quotesForRfq} />
    </div>
  );
};
