import React from 'react';
import Routing from 'routing';
import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { EstimateOneLogo } from '@itp-subbie/common/EstimateOneLogo';
import { Action, AnalyticsEndpoint, Event, useAnalytics } from '../../../hooks/Analytics';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export const ItpSignupBanner = () => {
  const { addEvent } = useAnalytics(Event.INTERACT, AnalyticsEndpoint.Itp);

  const handleClick = () => {
    addEvent({ action: Action.ITP_SIDE_BANNER_SIGNUP_CLICKED });
    window.location.assign(Routing.generate('app_signup_index', { src: 'itp-banner' }));
  };
  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.contentContainer}>
        <EstimateOneLogo />
        <h2 className={styles.title}>Find more opportunities</h2>
        <p className={styles.subheading}>Be the first to know</p>
        <p>
          See new projects on our noticeboard as soon as they go out to tender, direct from the
          builders that post them.
        </p>
        <ul className={styles.benefitList}>
          <li>569 open tenders on EstimateOne right now</li>
          <li>12,532 projects added in the last 12 months</li>
          <li>$56 billion total value</li>
          <li>Over 150,000 subcontractors and suppliers</li>
        </ul>
        <div className={styles.buttonContainer}>
          <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} onClick={handleClick}>
            Create free account
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ItpSignupBannerNoNoticeboard = ({
  slug,
  rfqId,
  rfqHash,
}: {
  slug: string;
  rfqId: EntityId;
  rfqHash: string;
}) => {
  const { addEvent } = useAnalytics(Event.INTERACT, AnalyticsEndpoint.Itp);

  const handleClick = () => {
    addEvent({ action: Action.ITP_SIDE_BANNER_SIGNUP_CLICKED });
    /**
     * @see assets/src/js/itp/cta.js
     */
    window.dispatchEvent(
      new CustomEvent('itp_cta_signup_from_react_banner', { detail: { slug, rfqId, rfqHash } }),
    );
  };
  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.contentContainer}>
        <EstimateOneLogo />
        <h2 className={styles.title}>The better way to manage your quotes</h2>
        <p className={styles.subheading}>Manage your quote requests in one place</p>
        <p>Create a free subcontractor account with EstimateOne and:</p>
        <ul className={styles.benefitList}>
          <li>Get automatic notifications when a package has been updated</li>
          <li>Easily search within the documents with in-browser document viewing</li>
          <li>Respond to enquiries from Main Contractors</li>
          <li>Priority access to new features</li>
        </ul>
        <div className={styles.buttonContainer}>
          <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} onClick={handleClick}>
            Create free account
          </Button>
        </div>
      </div>
    </div>
  );
};
