import React, { ReactNode } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  useQuery,
  gql,
  NormalizedCacheObject,
} from '@apollo/client';
import { InMemoryCache, PossibleTypesMap } from '@apollo/client/cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import Routing from 'routing';
import ApiVersionMismatchError from '../../js/classes/error/ApiVersionMismatchError';
import LoggedOutError from '../../js/classes/error/LoggedOutError';
import { SchemaName } from './constants';
import adminFragments from './fragments-admin.json';
import builderFragments from './fragments-builder.json';
import itpSubbieFragments from './fragments-itp-subbie.json';
import subbieFragments from './fragments-subbie.json';
import supplierInsightsFragments from './fragments-supplier-insights.json';

type ErrCallback = (err: Error) => void;

const commonLinkOptions = {
  credentials: 'same-origin',
};

const getEndpoint = (schemaName: SchemaName) =>
  Routing.generate('overblog_graphql_multiple_endpoint', { schemaName });

const LOCAL_STATE = gql`
  query LocalState {
    version @client
    errorMessage @client
    isLoggedIn @client
    requiresReload @client
  }
`;

const versionCheckLink = (cache: InMemoryCache, onVersionMismatch: () => void) =>
  new ApolloLink((operation, forward) =>
    forward(operation).map((data) => {
      const serverVersion: number | undefined = data?.extensions?.version;
      // Extension is not enabled - skip any further checks
      if (serverVersion === undefined) return data;

      const cacheVersion = cache.read<{ version: number }>({
        query: LOCAL_STATE,
        optimistic: false,
      })?.version;

      if (cacheVersion !== null && serverVersion !== cacheVersion) {
        onVersionMismatch();
      }

      cache.writeQuery({
        query: LOCAL_STATE,
        data: {
          version: serverVersion,
        },
      });

      return data;
    }),
  );

const tryParseErrorLink = (
  onLoginRedirect: ErrCallback,
  onParseError: ErrCallback,
  onNetworkError: ErrCallback,
) =>
  onError(({ networkError, operation }) => {
    // queries and mutations can add this to the context to handle network errors themselves
    const { hasCustomNetworkErrorHandler } = operation.getContext();

    if (networkError) {
      if ('statusCode' in networkError && networkError.statusCode === 403) {
        return onLoginRedirect(networkError);
      }
      // TODO: remove this in favour of the explicit 403 check above
      if (networkError.name === 'ServerParseError') {
        if ('response' in networkError) {
          // If we were redirected, assume it is because we were not logged in
          // and were redirected to the login page
          if (networkError.response.redirected) {
            return onLoginRedirect(networkError);
          }
        }
        return onParseError(networkError);
      }
      return hasCustomNetworkErrorHandler ? undefined : onNetworkError(networkError);
    }
    return undefined;
  });

const httpLink = (uri: string, batch = false) =>
  batch
    ? new BatchHttpLink({
        uri: `${uri}/batch`,
        ...commonLinkOptions,
      })
    : new HttpLink({
        uri,
        ...commonLinkOptions,
      });

const createClientWithLink = (
  link: ApolloLink,
  { possibleTypes }: { possibleTypes: PossibleTypesMap },
) => {
  const cache = new InMemoryCache({ possibleTypes });

  const writeCacheError = (message: string, isLoggedIn = true) => {
    cache.writeQuery({
      query: LOCAL_STATE,
      data: {
        errorMessage: message,
        isLoggedIn,
      },
    });
  };

  const handleGenericError = ({ message }: { message: string }) => writeCacheError(message);
  const client = new ApolloClient({
    link: ApolloLink.from([
      versionCheckLink(cache, () =>
        cache.writeQuery({ query: LOCAL_STATE, data: { requiresReload: true } }),
      ),
      tryParseErrorLink(
        ({ message }) => writeCacheError(message, false),
        handleGenericError,
        handleGenericError,
      ),
      link,
    ]),
    cache,
  });

  cache.writeQuery({
    query: LOCAL_STATE,
    data: {
      version: null,
      requiresReload: false,
      errorMessage: null,
      isLoggedIn: true,
    },
  });

  return client;
};

const createClient = (uri: string, { possibleTypes }: { possibleTypes: PossibleTypesMap }) =>
  createClientWithLink(
    /*
     * Disabled until we can figure out how to fix an issue
     * with partially-hydrated entities being used in subsequent queries
     * in a batch leading to invariant behaviour.
     */
    httpLink(uri, false),
    { possibleTypes },
  );

const supplierInsightsLink = httpLink(getEndpoint(SchemaName.SupplierInsights));
const subbieLink = httpLink(getEndpoint(SchemaName.Subbie));

export const createAdminClient = (): ApolloClient<NormalizedCacheObject> =>
  createClient(getEndpoint(SchemaName.Admin), adminFragments);
export const createBuilderClient = (): ApolloClient<NormalizedCacheObject> =>
  createClient(getEndpoint(SchemaName.Builder), builderFragments);
export const createSubbieClient = (): ApolloClient<NormalizedCacheObject> =>
  createClient(getEndpoint(SchemaName.Subbie), subbieFragments);
export const createItpSubbieClient = (): ApolloClient<NormalizedCacheObject> =>
  createClient(getEndpoint(SchemaName.ItpSubbie), itpSubbieFragments);
export const createLeadsClient = (): ApolloClient<NormalizedCacheObject> =>
  createClient(getEndpoint(SchemaName.Leads), { possibleTypes: {} });
export const createSupplierInsightsClient = (): ApolloClient<NormalizedCacheObject> =>
  createClientWithLink(
    ApolloLink.split(
      (operation) => operation.operationName.startsWith('SupplierInsights'),
      supplierInsightsLink,
      subbieLink,
    ),
    {
      possibleTypes: {
        ...supplierInsightsFragments.possibleTypes,
        ...subbieFragments.possibleTypes,
      },
    },
  );

/*
 * This is a very roundabout way to get error data out of apollo client
 * in a way that is compatible with <ErrorBoundary />
 */
const ErrorThrower = ({ children }: { children: ReactNode }) => {
  const { data } = useQuery<{
    errorMessage?: string;
    isLoggedIn: boolean;
    requiresReload: boolean;
  }>(LOCAL_STATE);

  if (data?.requiresReload) throw new ApiVersionMismatchError();

  const errorMessage = data?.errorMessage;

  if (errorMessage) {
    if (!data?.isLoggedIn) throw new LoggedOutError(errorMessage);
    // TODO: remove this logic - we should never reach this branch (see line 88)
    // Internet Explorer has a slightly different error format
    if (errorMessage.includes('JSON.parse Error')) throw new LoggedOutError(errorMessage);
    throw Error(errorMessage);
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const E1ApolloProvider = ({
  children,
  client,
}: {
  children: ReactNode;
  client: ApolloClient<NormalizedCacheObject>;
}) => (
  <ApolloProvider client={client}>
    <ErrorThrower>{children}</ErrorThrower>
  </ApolloProvider>
);

export default E1ApolloProvider;
