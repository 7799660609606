import { FORM_ERROR } from 'final-form';
import { CustomOffsets, FocusOnErrorsDecoratorOptions } from './types';

export const OFFSET_FIELD_DEFAULT = 45;
export const OFFSET_GLOBAL_ERRORS = 10;

export const combineValues = <T>(defaultValue: T | undefined, customValue: T) => {
  if (defaultValue === undefined || Array.isArray(customValue)) return customValue;
  if (typeof customValue === 'object') return { ...defaultValue, ...customValue };
  return customValue;
};

export const combineOptions = (
  defaultOptions: FocusOnErrorsDecoratorOptions,
  customOptions: FocusOnErrorsDecoratorOptions,
) => {
  const combinedCommonOptions = Object.entries(customOptions).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: combineValues<typeof v>(defaultOptions[k as keyof FocusOnErrorsDecoratorOptions], v),
    }),
    {},
  );

  return { ...defaultOptions, ...combinedCommonOptions };
};

export const getOffset = (elementName: string, customOffsets?: CustomOffsets): number => {
  if (elementName === FORM_ERROR) return OFFSET_GLOBAL_ERRORS;
  const offsetValue = customOffsets ? customOffsets[elementName] : undefined;
  return offsetValue !== undefined ? offsetValue : OFFSET_FIELD_DEFAULT;
};
