export default class TokenCache {
  private expiresAtUnixMillis: number;
  private fetchTokenPromise?: Promise<string>;

  constructor(
    private tokenUri: string,
    private tokenLifetimeSeconds = 1800,
  ) {
    this.expiresAtUnixMillis = this.getNewExpiry();
  }

  private fetchToken(): Promise<string> {
    return fetch(this.tokenUri)
      .then((resp) => resp.json())
      .then(({ token }) => token);
  }

  public getToken(): Promise<string> {
    if (this.fetchTokenPromise === undefined || this.hasExpired()) {
      this.expiresAtUnixMillis = this.getNewExpiry();
      this.fetchTokenPromise = this.fetchToken();
    }

    return this.fetchTokenPromise;
  }

  public expireToken(): void {
    this.fetchTokenPromise = undefined;
  }

  private hasExpired(): boolean {
    return Date.now() >= this.expiresAtUnixMillis;
  }

  private getNewExpiry(): number {
    return Date.now() + this.tokenLifetimeSeconds * 1e3;
  }
}
