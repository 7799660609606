export default class ApiVersionMismatchError extends Error {
  public code: number;

  constructor(public message: string = 'version mismatch') {
    super(message);
    this.name = 'ApiVersionMismatchError';
    this.code = 999;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
