import React, { useContext } from 'react';
import { CurrencyInfoContext } from '@shared';
import { CurrencyField } from '@shared/FinalForm/Field';
import { QuoteFormCommonFieldName } from '@ascension/enums';

const QuoteAmountField = () => {
  const [{ currencyInfo }] = useContext(CurrencyInfoContext);

  return (
    <CurrencyField
      fieldName={QuoteFormCommonFieldName.Amount}
      id="quote-amount"
      label={`Quote amount (excluding ${currencyInfo?.excludeTaxCode ?? 'GST'})`}
      currencySymbol={currencyInfo?.currencySymbol}
      isRequired
    />
  );
};

export default QuoteAmountField;
