import React, { useContext, useEffect } from 'react';
import { ItpCreateQuoteForm } from '../../features/itp-quotes/ItpQuotesForm';
import { QuotesTables } from '../../features/itp-quotes/QuotesTables';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { CurrencyInfoContext } from '@shared';
import { ItpGlobalStyleWrapper } from '@shared/Itp/ItpStyleWrapper';
import { useItpSubbieContext } from '../../context';
import { Action, AnalyticsEndpoint, Event, useAnalytics } from '../../../hooks/Analytics';
import { getCurrencyInfo } from '@ascension/enums';

type ItpQuotesPageProps = {
  stageCountryId: number;
};

export const ItpQuotePage = ({ stageCountryId }: ItpQuotesPageProps) => {
  const [{ quotesForRfq, fileManagerLocale, renderTable }] = useItpSubbieContext();

  const { addEvent } = useAnalytics(Event.INTERACT, AnalyticsEndpoint.Itp);

  useEffect(() => {
    const addQuoteButton = document.querySelector<HTMLButtonElement>('#itp-add-quote-button');

    if (!addQuoteButton) {
      return undefined;
    }

    const listener = () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      addEvent({ action: Action.ITP_QUOTE_ADD_BUTTON_CLICKED });
    };

    addQuoteButton.addEventListener('click', listener);

    return () => {
      addQuoteButton.removeEventListener('click', listener);
    };
  });

  const [, setCurrencyInfoContext] = useContext(CurrencyInfoContext);
  useEffect(() => {
    const currencyInfo = getCurrencyInfo(stageCountryId);
    setCurrencyInfoContext({ currencyInfo });
  }, [stageCountryId, setCurrencyInfoContext]);

  if (!quotesForRfq) return <LoadingSpinner />;

  if (renderTable) return <QuotesTables />;

  return (
    <ItpGlobalStyleWrapper>
      <ItpCreateQuoteForm fileManagerLocale={fileManagerLocale} />
    </ItpGlobalStyleWrapper>
  );
};
