import { convertDistance, getDistance } from 'geolib';

export type NonNullableRecord<T extends object | undefined | null> = Required<{
  [k in keyof T]-?: NonNullable<T[k]>;
}>;

export enum DistanceUnit {
  KM = 'km',
  MILES = 'mi',
}

const getUserDisplayUnits = ({ imperialUnitsSetting }: { imperialUnitsSetting: boolean }) =>
  imperialUnitsSetting ? DistanceUnit.MILES : DistanceUnit.KM;

const hasValidLocation = (address: Geolocation | null): address is NonNullableRecord<Geolocation> =>
  address !== null && typeof address.latitude === 'number' && typeof address.longitude === 'number';

export const localisedDistanceString = (
  metres: number,
  imperialUnitsSetting: boolean,
  fractionDigits: number = 1,
) => {
  const unit = getUserDisplayUnits({ imperialUnitsSetting });

  const distance = convertDistance(metres, unit);

  if (distance < 1) {
    return `< 1${unit}`;
  }

  return `${distance.toFixed(fractionDigits)}${unit}`;
};

export type Geolocation = {
  latitude?: number | null;
  longitude?: number | null;
};

export type Locatable = {
  address: Geolocation | null;
};

export type LocatableUser = Locatable & { imperialUnitsSetting: boolean };

const calcUserProjectDistance = (
  user: LocatableUser,
  project: Locatable,
  units: string | null = null,
) => {
  const userAddress = user.address;
  const projectAddress = project.address;
  const validCoords = hasValidLocation(userAddress) && hasValidLocation(projectAddress);

  if (!validCoords) return null;

  const distanceInMeters = getDistance(
    {
      latitude: userAddress.latitude,
      longitude: userAddress.longitude,
    },
    {
      latitude: projectAddress.latitude,
      longitude: projectAddress.longitude,
    },
  );

  const exactDistance = convertDistance(distanceInMeters, units || getUserDisplayUnits(user));

  return Math.round(exactDistance);
};

// eslint-disable-next-line consistent-return -- consistent-return rule isn't smart enough to know that the switch is exhaustive
const unitToKmCoarse = (unitValue: number, sourceUnit: DistanceUnit) => {
  // eslint-disable-next-line default-case -- @typescript-eslint/switch-exhaustiveness-check will cover this
  switch (sourceUnit) {
    case DistanceUnit.KM:
      return unitValue;
    case DistanceUnit.MILES:
      return Math.round(unitValue * 1.6);
  }
};

// eslint-disable-next-line consistent-return -- consistent-return rule isn't smart enough to know that the switch is exhaustive
const kmToUnitCoarse = (kmValue: number, targetUnit: DistanceUnit) => {
  // eslint-disable-next-line default-case -- @typescript-eslint/switch-exhaustiveness-check will cover this
  switch (targetUnit) {
    case DistanceUnit.KM:
      return kmValue;
    case DistanceUnit.MILES:
      return Math.round(kmValue / 1.6);
  }
};
export { calcUserProjectDistance, getUserDisplayUnits, unitToKmCoarse, kmToUnitCoarse };
