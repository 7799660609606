import React, { ReactNode } from 'react';
import Routing from 'routing';
import { AscensionScopesGatewayProvider } from '@scope-of-works/providers/AscensionScopesGatewayProvider';
import { AscensionSupplierInsightsGatewayProvider } from '@supplier-insights/providers/AscensionSupplierInsightsGatewayProvider';
import E1ApolloProvider, {
  createAdminClient,
  createBuilderClient,
  createSubbieClient,
  createItpSubbieClient,
  createSupplierInsightsClient,
  createLeadsClient,
} from './E1ApolloProvider';
import GatewayProvider from './GatewayProvider';
import { SchemaName } from './constants';
import builderFragments from './fragments-builder.json';
import * as supplierInsightsFragment from './fragments-supplier-insights.json';

const adminApiClient = createAdminClient();
const builderApiClient = createBuilderClient();
const subbieApiClient = createSubbieClient();
const itpSubbieApiClient = createItpSubbieClient();
const supplierInsightsApiClient = createSupplierInsightsClient();
const leadsApiClient = createLeadsClient();

const getBodyElementDatum = (name: string): string | undefined =>
  document.getElementsByTagName('body')?.item(0)?.dataset[name] || undefined;

export const AdminApiProvider = ({ children }: { children?: ReactNode }) => {
  const gatewayBaseUri = getBodyElementDatum('gatewayBaseUri');

  if (gatewayBaseUri) {
    return (
      <GatewayProvider
        gatewayBaseUri={gatewayBaseUri}
        idpAuthoriseUri={Routing.generate('admin_gateway_jwt_authorise')}
        applicationBaseUri={Routing.generate('overblog_graphql_multiple_endpoint', {
          schemaName: SchemaName.Admin,
        })}
      >
        {children}
      </GatewayProvider>
    );
  }

  if (!adminApiClient) throw Error('No admin API client provided');
  return <E1ApolloProvider client={adminApiClient}>{children}</E1ApolloProvider>;
};

export const BuilderApiProvider = ({ children }: { children?: ReactNode }) => {
  if (!builderApiClient) throw Error('No builder API client provided');

  return <E1ApolloProvider client={builderApiClient}>{children}</E1ApolloProvider>;
};

export const BuilderAndScopesApiProvider = ({ children }: { children?: ReactNode }) => {
  const gatewayUri = document.getElementById('gateway-base-uri-id')?.dataset.gatewayBaseUri;

  if (!gatewayUri) {
    throw Error('Gateway URI is missing');
  }

  return (
    <AscensionScopesGatewayProvider
      gatewayBaseUri={gatewayUri}
      idpAuthoriseUri={Routing.generate('gateway_jwt_authorise')}
      fragment={builderFragments}
      applicationBaseUri={Routing.generate('overblog_graphql_multiple_endpoint', {
        schemaName: SchemaName.Builder,
      })}
    >
      {children}
    </AscensionScopesGatewayProvider>
  );
};

export const SubbieApiProvider = ({ children }: { children?: ReactNode }) => {
  if (!subbieApiClient) throw Error('No subbie API client provided');

  return <E1ApolloProvider client={subbieApiClient}>{children}</E1ApolloProvider>;
};

export const ItpSubbieApiProvider = ({ children }: { children?: ReactNode }) => {
  if (!itpSubbieApiClient) throw Error('No ITP subbie API client provided');

  return <E1ApolloProvider client={itpSubbieApiClient}>{children}</E1ApolloProvider>;
};

export const LeadsApiProvider = ({ children }: { children?: ReactNode }) => {
  if (!leadsApiClient) throw Error('No Leads API client provided');

  return <E1ApolloProvider client={leadsApiClient}>{children}</E1ApolloProvider>;
};

export const SupplierInsightsApiProvider = ({ children }: { children?: ReactNode }) => {
  const gatewayBaseUri = document.getElementById('insights_page')?.dataset.gatewayBaseUri;

  if (gatewayBaseUri) {
    return (
      <AscensionSupplierInsightsGatewayProvider
        gatewayBaseUri={gatewayBaseUri}
        idpAuthoriseUri={Routing.generate('gateway_jwt_authorise')}
        applicationBaseUri={Routing.generate('overblog_graphql_multiple_endpoint', {
          schemaName: SchemaName.Subbie,
        })}
        fragment={supplierInsightsFragment}
      >
        {children}
      </AscensionSupplierInsightsGatewayProvider>
    );
  }

  if (!supplierInsightsApiClient) throw Error('No Supplier Insights API client provided');

  return <E1ApolloProvider client={supplierInsightsApiClient}>{children}</E1ApolloProvider>;
};
