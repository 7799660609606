import React from 'react';
import { Table } from '@estimateone/frontend-components';
import { readableFileSize } from '@ascension/js/utils/filesize';
import readableFileName from '@shared/Quotes/utils/readableFileName';
import readableFileType from '@shared/Quotes/utils/readableFileType';
import { QuoteExtraFile } from '@api/fragments/Quotes/types';
import styles from './styles.scss';

type ExtraFilesTableProps = {
  additionalFiles: Partial<QuoteExtraFile>[];
};

export const ExtraFilesTable = ({ additionalFiles }: ExtraFilesTableProps) => (
  <>
    <h5 className={styles.extraFilesHeading}>Extra Files</h5>
    <div className={styles.extraFilesTable}>
      <Table
        columns={[
          {
            Header: 'Type',
            accessor: ({ fileName }: QuoteExtraFile) => readableFileType(fileName),
            disableSortBy: true,
          },
          {
            Header: 'Size',
            accessor: ({ fileSize }: QuoteExtraFile) => readableFileSize(fileSize),
            disableSortBy: true,
          },
          {
            Header: 'Title',
            accessor: ({ fileName }: QuoteExtraFile) => readableFileName(fileName),
            disableSortBy: true,
          },
        ]}
        data={additionalFiles}
      />
    </div>
  </>
);
