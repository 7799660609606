import { Decorator } from 'final-form';
import {
  focusOnErrorsDecorator,
  FocusOnErrorsDecoratorOptions,
  combineOptions,
} from '../../FinalForm/decorators/focusOnErrors';
import { QuoteFormCommonFieldName } from '../../../../enums';

export const focusOnQuoteErrorsDecorator = <FormValues, Errors extends Record<string, unknown>>(
  formId: string,
  customOptions: FocusOnErrorsDecoratorOptions = {},
): Decorator<FormValues> => {
  const defaultQuoteFormOptions: FocusOnErrorsDecoratorOptions = {
    customOffsets: {
      [QuoteFormCommonFieldName.QuoteFile]: 15,
    },
  };

  return focusOnErrorsDecorator<FormValues, Errors>(
    formId,
    combineOptions(defaultQuoteFormOptions, customOptions),
  );
};
