import { gql } from '@apollo/client';

const Errors = {
  fragments: {
    error: gql`
      fragment ApiError on Error {
        field
        message
      }
    `,
  },
};

export default Errors;
