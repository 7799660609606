import React, { ReactNode } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import { getClassForFontStyle } from '../utils';
import { FontStyle } from '../enums';
import styles from './styles.scss';

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

type HeadingProps = {
  children: ReactNode;
  level: HeadingLevel;
  renderAs?: FontStyle;
  isHidden?: boolean;
};

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const Heading = ({ children, level, renderAs, isHidden = false }: HeadingProps) => {
  const Tag = `h${level}` as HeadingTag;
  const fontStyle = renderAs || (`heading${level}` as FontStyle);

  return (
    <Tag
      className={
        isHidden ? styles.hidden : joinClassNames(styles.heading, getClassForFontStyle(fontStyle))
      }
    >
      {children}
    </Tag>
  );
};

export default Heading;
