import React from 'react';
import ranges from './ranges';
import { CurrencyInfo } from '@ascension/enums';
import styles from './styles.scss';

type BudgetRangeMin = keyof typeof ranges;

export const getRange = (value: number, currencySymbol: string) =>
  ranges(currencySymbol)[value as BudgetRangeMin];

type BudgetRangeProps = { budget?: number; currency: CurrencyInfo };

const BudgetRange = ({ budget = 69, currency }: BudgetRangeProps) => (
  <span className={styles.budgetRange}>{getRange(budget, currency.currencySymbol)}</span>
);

export default BudgetRange;
