import React, { createContext, ReactNode, useCallback } from 'react';

type TwigGlobalState = { options: Record<string, boolean> };
type TwigGlobalProviderProps = TwigGlobalState & { children: ReactNode };

type TwigGlobalContextState = {
  getBooleanValue(key: string, defaultValue: boolean): boolean;
} | null;

export const TwigGlobalContext = createContext<TwigGlobalContextState>(null);
const { Provider } = TwigGlobalContext;

export const TwigGlobalProvider = ({ children, options }: TwigGlobalProviderProps) => {
  const getBooleanValue = useCallback(
    (key: string, defaultValue: boolean) => options[key] ?? defaultValue,
    [options],
  );
  return <Provider value={{ getBooleanValue }}>{children}</Provider>;
};
