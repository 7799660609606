import React from 'react';

const getDescription = (hasDownloaded: boolean, hasLatestDocs: boolean) => {
  if (hasDownloaded === false) return 'Not accessed';
  if (hasLatestDocs === false) return 'Addendum not viewed';
  return 'Current';
};

const DocStatus: React.VFC<{ hasLatestDocs: boolean; hasDownloaded: boolean }> = ({
  hasLatestDocs = false,
  hasDownloaded = false,
}) => {
  const docsAreCurrent = hasLatestDocs && hasDownloaded;
  const description = getDescription(hasDownloaded, hasLatestDocs);
  const className = docsAreCurrent ? 'success' : 'danger';
  const icon = docsAreCurrent ? 'check icon-sm' : 'flag';

  return (
    <span className={`${className} white-space-nowrap`}>
      <i className={`icon icon-${icon} icon-left`} />
      {description}
    </span>
  );
};

export default DocStatus;
