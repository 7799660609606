import React from 'react';
import {
  GroupBase,
  OnChangeValue,
  OptionsOrGroups,
  PropsValue,
  StylesConfig,
  Props as ReactSelectProps,
} from 'react-select';
import { FormatOptionLabelMeta } from 'react-select/base';
import {
  Select as FecSelect,
  SelectCustomProps as FecSelectProps,
} from '@estimateone/frontend-components';
import omit from 'lodash/omit';
import { findOptionOrNested, LabelledOption } from './util';
import './styles.scss';

export type { LabelledOption };
export { SelectSize, prefixMenuOptionsWithCheckIcon } from '@estimateone/frontend-components';

type SelectFormGroupProps<
  OptionValue = Record<string, string>,
  IsMulti extends boolean = false,
> = FecSelectProps & {
  onChange: (value: OnChangeValue<LabelledOption<OptionValue>, IsMulti>) => void;
  options: OptionsOrGroups<LabelledOption<OptionValue>, GroupBase<LabelledOption<OptionValue>>>;
  formatOptionLabel?: (
    option: LabelledOption<OptionValue>,
    labelMeta: FormatOptionLabelMeta<LabelledOption<OptionValue>>,
  ) => React.ReactNode;
  value?: PropsValue<OptionValue>;
  defaultValue?: PropsValue<LabelledOption<OptionValue>>;
  placeholder?: string;
  styles?: StylesConfig<
    LabelledOption<OptionValue>,
    IsMulti,
    GroupBase<LabelledOption<OptionValue>>
  >;
  isMulti: IsMulti;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  maxMenuHeight?: number;
  isSearchable?: boolean;
  isDisabled?: boolean;
  closeMenuOnSelect?: boolean;
  menuPortalTarget?: HTMLElement;
  components?: ReactSelectProps<
    LabelledOption<OptionValue>,
    IsMulti,
    GroupBase<LabelledOption<OptionValue>>
  >['components'];
};

const SelectFormGroup = <OptionValue, IsMulti extends boolean>({
  onChange,
  options,
  formatOptionLabel,
  value,
  placeholder,
  styles,
  isMulti,
  menuPortalTarget,
  menuPlacement = 'bottom',
  maxMenuHeight = 300,
  isSearchable = true,
  isDisabled = false,
  closeMenuOnSelect = true,
  ...fecSelectProps
}: SelectFormGroupProps<OptionValue, IsMulti>) => (
  <FecSelect<LabelledOption<OptionValue>, IsMulti>
    placeholder={placeholder}
    styles={styles}
    value={value ? findOptionOrNested(value, options) : null}
    options={options}
    formatOptionLabel={formatOptionLabel}
    onChange={onChange}
    isMulti={isMulti}
    menuPortalTarget={menuPortalTarget}
    menuPlacement={menuPlacement}
    maxMenuHeight={maxMenuHeight}
    isSearchable={isSearchable}
    isDisabled={isDisabled}
    closeMenuOnSelect={closeMenuOnSelect}
    {...fecSelectProps}
  />
);

type SelectProps<OptionValue, IsMulti extends boolean = false> = { name: string } & Omit<
  SelectFormGroupProps<OptionValue, IsMulti>,
  'isMulti' | 'onChange'
>;

export type SingleSelectionHandler<OptionValue> = (v: {
  name: string;
  selectedOption: LabelledOption<OptionValue> | null;
}) => void;

export type SingleSelectProps<OptionValue> = SelectProps<OptionValue, false> & {
  value?: OptionValue | null;
  onChange: SingleSelectionHandler<OptionValue>;
};

export const SingleSelect = <OptionValue,>(props: SingleSelectProps<OptionValue>): JSX.Element => {
  const { name, onChange } = props;

  const handleOnChange = (selectedOption?: LabelledOption<OptionValue> | null) => {
    onChange({ name, selectedOption: selectedOption ?? null });
  };

  return (
    <SelectFormGroup<OptionValue, false>
      {...omit(props, ['name', 'onChange'])}
      onChange={handleOnChange}
      isMulti={false}
    />
  );
};

export type MultiSelectProps<OptionValue> = SelectProps<OptionValue, true> & {
  value?: OptionValue[] | null;
  onChange: (v: { name: string; selectedOptions: readonly LabelledOption<OptionValue>[] }) => void;
};

export const MultiSelect = <OptionValue,>(props: MultiSelectProps<OptionValue>): JSX.Element => {
  const { name, onChange } = props;

  const handleOnChange = (selectedOptions?: readonly LabelledOption<OptionValue>[] | null) => {
    onChange({ name, selectedOptions: selectedOptions || [] });
  };

  return (
    <SelectFormGroup<OptionValue, true>
      {...omit(props, ['name', 'onChange'])}
      onChange={handleOnChange}
      isMulti
    />
  );
};
