import React from 'react';
import { Modal, Row, Col } from '@estimateone/frontend-components';
import { ItpModalButtons } from './ItpModalButtons';
import { Event, Action, useAnalytics, AnalyticsEndpoint } from '../../../../hooks/Analytics';

type QuoteDeleteModalProps = {
  isOpen: boolean;
  removeModal: () => void;
  handleRevise: () => void;
};

export const ItpReviseQuoteModal: React.VFC<QuoteDeleteModalProps> = ({
  isOpen = false,
  handleRevise,
  removeModal,
}) => {
  const { addEvent } = useAnalytics(Event.INTERACT, AnalyticsEndpoint.Itp);

  const handleSubmit = async () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({ action: Action.ITP_QUOTE_REVISE_CLICKED });
    handleRevise();
    removeModal();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={removeModal} rootElement="body">
      <Modal.Section>
        <Row>
          <Col>
            <h2>Revise Quote</h2>
            <p>Are you sure you want to revise this quote?</p>
            <p>Revising a quote will remove the previous quote submitted.</p>
          </Col>
        </Row>
        <ItpModalButtons
          submitBtnTitle="Yes, upload new"
          submitOnClick={handleSubmit}
          cancelOnClick={removeModal}
        />
      </Modal.Section>
    </Modal>
  );
};
