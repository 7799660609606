import React from 'react';
import { E1Link } from '@estimateone/frontend-components';
import { QuoteFileAction } from '@ascension/enums';

type FilenameOrLinkProps = {
  action?: QuoteFileAction;
  file: { fileName: string; viewUrl?: string };
};

const FilenameOrLink = ({ action, file: { fileName, viewUrl } }: FilenameOrLinkProps) =>
  action !== QuoteFileAction.Download ? (
    <span>{fileName}</span>
  ) : (
    <E1Link link={viewUrl} target="_blank" rel="noopener noreferrer">
      {fileName}
    </E1Link>
  );

export default FilenameOrLink;
