import { createGenericContext } from '@shared/context/createGenericContext';

export type LegacyNotification = {
  id?: number;
  type?: string;
  headline: string;
  message: string;
};

export type LegacyNotificationContext = {
  notifications: LegacyNotification[];
  addNotification: (n: LegacyNotification) => void;
  removeNotification: (n: LegacyNotification) => void;
};

const [useNotificationContext, Provider] =
  createGenericContext<LegacyNotificationContext>('Notification');

export const GenericNotificationProvider = Provider;
export default useNotificationContext;
