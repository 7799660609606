import React from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

type FilterButtonProps = {
  isActive: boolean;
  isHighlighted?: boolean;
  onClick: () => void;
  name: string;
  btnClassName?: string;
  children?: React.ReactNode;
};

export const FilterButton = ({
  isActive,
  isHighlighted,
  onClick,
  btnClassName,
  name,
  children,
}: FilterButtonProps): JSX.Element => {
  const className = joinClassNames(
    'btn',
    styles.btnFilter,
    isActive && styles.btnFilterActive,
    isHighlighted && styles.btnFilterHighlighted,
    btnClassName,
  );

  return (
    <button name={name} type="button" onClick={onClick} className={className}>
      {children}
    </button>
  );
};
