import React from 'react';
import { ItpSubbieApiProvider } from '@api/ApiProvider';
import { renderWithParents } from '@ascension/components/render-helper';
import { ItpSubbieErrorBoundary } from '@itp-subbie/ErrorBoundaries';
import {
  ItpSignupBanner,
  ItpSignupBannerNoNoticeboard,
} from '@itp-subbie/features/itp-signup-banner';
import { ItpQuotePage } from '@itp-subbie/pages/ItpQuotePage';
import { CurrencyInfoContextProvider } from '@shared';
import { FileManagerParams } from '@shared/FileUploader/types';
import { TwigGlobalProvider } from '@builder/context/TwigFeatureProvider/TwigGlobalProvider';
import { ItpSubbieContextProvider } from '@itp-subbie/context';

renderWithParents<
  {
    stageId: string;
    rfqId: string;
    openSignupModal: string;
    stageCountryId: string;
    hasFixedPriceQuotesFeature: string;
  } & Pick<FileManagerParams, 'fileManagerLocale'>
>(
  <ItpSubbieErrorBoundary />,
  <ItpSubbieApiProvider />,
  <CurrencyInfoContextProvider />,
)('itp-quote-submission-container')(
  ({
    stageId,
    rfqId,
    fileManagerLocale,
    openSignupModal,
    stageCountryId,
    hasFixedPriceQuotesFeature,
  }) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1' }}
    >
      <ItpSubbieContextProvider
        stageId={parseInt(stageId)}
        rfqId={parseInt(rfqId)}
        fileManagerLocale={fileManagerLocale}
        openSignupModal={openSignupModal === '1'}
      >
        <ItpQuotePage stageCountryId={parseInt(stageCountryId)} />
      </ItpSubbieContextProvider>
    </TwigGlobalProvider>
  ),
);

renderWithParents(<ItpSubbieErrorBoundary />)('itp-side-banner')(() => <ItpSignupBanner />);
renderWithParents<{ slug: string; rfqId: string; rfqHash: string }>(<ItpSubbieErrorBoundary />)(
  'itp-side-banner-no-noticeboard',
)(({ slug, rfqId, rfqHash }) => (
  <ItpSignupBannerNoNoticeboard slug={slug} rfqId={parseInt(rfqId)} rfqHash={rfqHash} />
));
