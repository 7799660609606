import { captureException } from '@sentry/browser';

type OptionalContext = Parameters<typeof captureException>[1];

export const reportError = (error: Error, context?: OptionalContext) => {
  if (process.env.NODE_ENV === 'development') {
    throw error;
  } else {
    captureException(error, context);
  }
};
