const FULL_SET_PKG_NAME = 'Full Set';

enum Placement {
  FULL_SET_PKG_NAME_FIRST = 1,
  FULL_SET_PKG_NAME_LAST = -1,
}

const comparePackageTitlesAlphaWithFullSetPlacement =
  (placement: Placement) =>
  (titleA: string, titleB: string): number => {
    if (titleA === FULL_SET_PKG_NAME) return -placement;
    if (titleB === FULL_SET_PKG_NAME) return placement;

    return titleA.localeCompare(titleB);
  };

export const comparePackageTitlesAlphaFullSetFirst = comparePackageTitlesAlphaWithFullSetPlacement(
  Placement.FULL_SET_PKG_NAME_FIRST,
);
export const comparePackageTitlesAlphaFullSetLast = comparePackageTitlesAlphaWithFullSetPlacement(
  Placement.FULL_SET_PKG_NAME_LAST,
);
