import { useCallback } from 'react';
import Routing from 'routing';
import E1Request from '../../../js/classes/E1Request';
import { fireAndForget } from '@shared/Util/async';
import { Action as AnalyticsAction } from '@ascension/components/hooks/Analytics/actions';
import { EntityId } from '../../../types';

export { AnalyticsAction as Action };

export enum Event {
  ADMIN = 'admin',
  AUTHENTICATION = 'auth',
  EXPERIMENT = 'experiment',
  FEATURE = 'feature',
  INTERACT = 'interact',
  ITP = 'itp',
  ITP_INTERACT = 'itp-interact',
  LANDING_PAGE = 'none1-subbie-landing',
  LP_INTERACT = 'lp-interact',
  LONGSELL = 'longsell',
  PROJECT = 'project',
  SEARCH = 'search',
  SUPPORT = 'support',
  UPSELL = 'upsell',
  VISIT = 'visit',
  ERROR = 'error',
}

export enum AnalyticsEndpoint {
  App = 'app_ajax_analytics_event',
  Itp = 'itp_ajax_analytics_event',
}

type Request<T> = {
  collection: Event;
  payload: Payload<T>;
  uri: string | null;
  logChannel?: string | null;
};

export interface AnalyticsHookInterface<T> {
  addEvent: (payload: Payload<T>, uri?: string, logChannel?: string) => void;
}

const postAnalyticsEvent = async <T>(
  collection: Event,
  payload: Payload<T>,
  analyticsEndpoint: AnalyticsEndpoint,
  uri?: string,
  logChannel?: string,
) => {
  const data: Request<T> = {
    collection,
    payload,
    uri: uri || window.location.pathname + window.location.search + window.location.hash,
    logChannel,
  };
  return new E1Request<{ success: true }, Request<T>>(
    Routing.generate(analyticsEndpoint),
    'POST',
    data,
  ).submit();
};

export type PayloadExtras = {
  [k: string]: string | string[] | EntityId | number | number[] | boolean | undefined;
};

export type Payload<T> = Readonly<T & { action: AnalyticsAction }>;

const useAnalytics = <T = PayloadExtras>(
  collection: Event,
  analyticsEndpoint: AnalyticsEndpoint = AnalyticsEndpoint.App,
): AnalyticsHookInterface<T> => {
  const addEvent = useCallback(
    (payload: Payload<T>, uri?: string, logChannel?: string) =>
      fireAndForget(() =>
        postAnalyticsEvent(collection, payload, analyticsEndpoint, uri, logChannel),
      ),
    [collection],
  );

  return { addEvent };
};

const useInteractAnalytics = <T = PayloadExtras>(): AnalyticsHookInterface<T> =>
  useAnalytics(Event.INTERACT);

export { useAnalytics, useInteractAnalytics };
