class Paginate {
  private readonly startRow: number;

  private readonly endRow: number;

  constructor(
    private page: number,
    private readonly perPage: number,
    private readonly totalRows: number,
  ) {
    this.startRow = Paginate.calcStartRow(page, perPage);
    this.endRow = Paginate.calcEndRow(page, perPage);
  }

  static calcStartRow(page: number, perPage: number) {
    return page * perPage - perPage + 1;
  }

  static calcEndRow(page: number, perPage: number) {
    return page * perPage;
  }

  calcPageCount() {
    return Math.ceil(this.totalRows / this.perPage);
  }

  isCurrentPage(index: number) {
    return index + 1 >= this.startRow && index + 1 <= this.endRow;
  }
}

export default Paginate;
