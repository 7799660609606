import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useHasFixedPriceQuotesFeature } from '@shared';
import { transformQuoteFormApiErrors } from '@shared/Quotes/Form/transformers';
import { QuoteFormCommonFieldValues } from '@shared/Quotes/Form/types';
import { ITP_RFQ_CONTEXT_QUERY } from '../../../context/queries';
import { CREATE_OR_REVISE_QUOTE_FOR_RFQ } from './mutations';
import {
  ItpCreateOrReviseQuoteForRfq,
  ItpCreateOrReviseQuoteForRfqVariables,
} from './types/ItpCreateOrReviseQuoteForRfq';
import { CreateOrReviseQuoteForRfqInput } from '@ascension/_gqltypes/itp-subbie.generated';
import { EntityId } from '@ascension/types';

export const useCreateOrReviseQuote = (rfqId: EntityId, openSignupModal: boolean) => {
  const reviseQuoteOperationName = getOperationName(ITP_RFQ_CONTEXT_QUERY);
  const hasFixedPriceQuotesFeature = useHasFixedPriceQuotesFeature();

  if (!reviseQuoteOperationName) {
    // TODO: make the mutation update the cache instead
    throw Error('Could not get operation name for ITP_RFQ_CONTEXT_QUERY');
  }

  const [createOrReviseQuote] = useMutation<
    ItpCreateOrReviseQuoteForRfq,
    ItpCreateOrReviseQuoteForRfqVariables
  >(CREATE_OR_REVISE_QUOTE_FOR_RFQ, {
    refetchQueries: [reviseQuoteOperationName],
    onCompleted: (data) => {
      if (data.createOrReviseQuoteForRfq.__typename === 'LimitedQuote') {
        if (openSignupModal) {
          // After mutation is successful, we will reload the page from the global js.
          // See assets/src/js/itp/cta.js
          const openSignUpModalEvent = new CustomEvent('openSignUpModal');
          document.dispatchEvent(openSignUpModalEvent);
          return data.createOrReviseQuoteForRfq.id;
        }
        location.reload();
      }
      return undefined;
    },
  });

  const submit = async (values: QuoteFormCommonFieldValues) => {
    const { quoteAmount, quoteDetails, quoteFile, quoteExtraFiles, confirmFixedPrice } = values;

    if (!quoteAmount || !quoteFile) {
      return undefined;
    }

    const transformedInput: CreateOrReviseQuoteForRfqInput = {
      rfqId,
      amount: quoteAmount,
      details: quoteDetails,
      quoteFile: {
        hash: quoteFile.hash,
        fileSize: quoteFile.size,
        fileName: quoteFile.name,
        contentType: quoteFile.type,
      },
      additionalFiles: quoteExtraFiles?.map((file) => ({
        hash: file.hash,
        fileSize: file.size,
        fileName: file.name,
        contentType: file.type,
      })),
      confirmFixedPrice: hasFixedPriceQuotesFeature ? (confirmFixedPrice ?? false) : undefined,
    };

    const { data: response } = await createOrReviseQuote({
      variables: { input: transformedInput },
    });

    if (response?.createOrReviseQuoteForRfq.__typename === 'Errors') {
      return transformQuoteFormApiErrors(response.createOrReviseQuoteForRfq.errors);
    }

    return undefined;
  };

  return { submit };
};
