import { escape } from 'lodash';
import E1Request, { FlashMessageType } from '../../../js/classes/E1Request';

interface FlashMessageOpts {
  title: string;
  message?: string;
  dangerouslyAllowHTML?: boolean;
}

type MessageCallable = (opts: FlashMessageOpts) => void;

interface MessageTypes {
  success: MessageCallable;
  warning: MessageCallable;
}

const useFlashMessage = (): MessageTypes => {
  const success = ({
    title,
    message,
    dangerouslyAllowHTML = false,
    ...options
  }: FlashMessageOpts) =>
    E1Request.flashMessage({
      ...options,
      title: dangerouslyAllowHTML ? title : escape(title),
      message: dangerouslyAllowHTML ? message : escape(message),
      ...{ type: FlashMessageType.E1_SUCCESS },
    });
  const warning = ({
    title,
    message,
    dangerouslyAllowHTML = false,
    ...options
  }: FlashMessageOpts) =>
    E1Request.flashMessage({
      ...options,
      title: dangerouslyAllowHTML ? title : escape(title),
      message: dangerouslyAllowHTML ? message : escape(message),
      ...{ type: FlashMessageType.WARNING },
    });

  return { success, warning };
};

export default useFlashMessage;
