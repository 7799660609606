import { GroupBase, OptionsOrGroups, PropsValue } from 'react-select';

export interface LabelledOption<OptionValue> {
  value: OptionValue;
  label: string;
}

// todo: get rid of the LabelledOption type here so the utility can be generic

function findOptionOrOptions<T>(val: PropsValue<T>, opts: readonly LabelledOption<T>[]) {
  return Array.isArray(val)
    ? opts.filter(({ value }) => val.includes(value))
    : opts.find(({ value: v }) => v === val);
}

function findNestedOptionOrOptions<T>(val: PropsValue<T>, opts: GroupBase<LabelledOption<T>>[]) {
  if (Array.isArray(val)) {
    const allOptions = opts.reduce(
      (acc: LabelledOption<T>[], group) => acc.concat(group.options),
      new Array<LabelledOption<T>>(),
    );

    return allOptions.filter(({ value }) => val.includes(value));
  }

  return opts.reduce(
    (acc: LabelledOption<T>, { options: subOpts }) => acc || findOptionOrOptions<T>(val, subOpts),
    undefined,
  );
}

export function findOptionOrNested<T>(
  val: PropsValue<T>,
  opts: OptionsOrGroups<LabelledOption<T>, GroupBase<LabelledOption<T>>>,
) {
  if (!opts.length) return undefined;
  const [firstOpt] = opts;
  if ('options' in firstOpt) {
    return findNestedOptionOrOptions<T>(val, opts as GroupBase<LabelledOption<T>>[]);
  }
  return findOptionOrOptions<T>(val, opts as LabelledOption<T>[]);
}
