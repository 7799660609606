import { reportError } from '@ascension/components/helpers/errorReporter';

/**
 * Runs a function in a "detached" async context, so it doesn't block the current control flow.
 * Any error thrown will be sent to sentry, but will not bubble up to the caller.
 *
 * You would want to use this function if you want to "fire and forget" an operation that the
 * user does not have to wait for (e.g. sending analytics events)
 *
 * Using this function over an adhoc async IIFE or just ignoring the return of an async function
 * has a few benefits:
 *
 * 1. It makes it explicit that you want the function to run in a "detached" context, so there
 *    isn't any confusion when a promise is unhandled, and we don't need to ignore the eslint
 *    no-floating-promises rule.
 * 2. It ensures that any errors are captured and sent to sentry. If a promise is unhandled,
 *    errors can be completely ignored in certain contexts, which means things could be silently
 *    failing.
 *
 * Also note that a function run in an async context will run concurrently with other tasks,
 * which is great for I/O bound tasks. However, Javascript is inherently single threaded,
 * so if you have a task that is CPU bound, it can still block the app. You would want something
 * like a web worker for that.
 *
 * @param promiseFn the function that returns a promise that you want to be immediately executed
 *
 * @example
 * // fires an analytics event
 * fireAndForget(() => addEvent({ action: 'some action' }));
 */
export const fireAndForget = <T>(promiseFn: () => Promise<T>): void => {
  promiseFn().catch(reportError);
};
