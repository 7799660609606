import { BillingCycle } from '../../../../enums';

export const getBillingCycleString = (billingCycle: number): string => {
  switch (billingCycle) {
    case BillingCycle.Months1:
      return 'per month';

    case BillingCycle.Months3:
      return 'per quarter';

    case BillingCycle.Months6:
      return 'every six months';

    case BillingCycle.Months12:
    case BillingCycle.Months14:
      return 'per annum';

    case BillingCycle.Months24:
    case BillingCycle.Months36:
      return `every ${billingCycle / 12} years`;

    default:
      throw Error(`Unknown billing cycle ${billingCycle}`);
  }
};
