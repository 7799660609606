import {
  PossibleTypesMap,
  ApolloClient,
  NormalizedCacheObject,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import TokenCache from '@api/GatewayProvider/TokenCache';
import { isServerError } from '@api/GatewayProvider/util';
import { SCOPES_APOLLO_CLIENT_NAME } from '../constants';
import { getScopesGatewayUriFromAscensionGatewayUri } from '../util';

export type GatewayClient = (
  gatewayBaseUri: string,
  idpAuthoriseUri: string,
  applicationBaseUri: string,
  fragment?: { possibleTypes: PossibleTypesMap },
) => ApolloClient<NormalizedCacheObject>;

export const createGatewayClient: GatewayClient = (
  gatewayBaseUri,
  idpAuthoriseUri,
  applicationBaseUri,
  fragment,
) => {
  const tokenCache = new TokenCache(idpAuthoriseUri);

  const authLink = setContext(async (_, { headers }) => ({
    // return the headers to the context so httpLink can read them
    headers: {
      authorization: `Bearer ${await tokenCache.getToken()}`,
      ...headers,
    },
  }));

  const resetTokenOnErrorLink = onError(({ networkError }) => {
    if (networkError && isServerError(networkError) && networkError.statusCode === 401) {
      tokenCache.expireToken();
    }
  });

  // This will retry both getting the token and the GraphQL request 5 times with backoff
  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#default-configuration
  const retryLink = new RetryLink();

  const ascensionLink = new HttpLink({
    uri: applicationBaseUri,
  });

  const scopesHttpLink = createHttpLink({
    uri: getScopesGatewayUriFromAscensionGatewayUri(gatewayBaseUri),
  });

  const scopesLink = ApolloLink.from([authLink, scopesHttpLink]);

  const scopeOrAscensionLink = ApolloLink.split(
    (operation) => operation.getContext().clientName === SCOPES_APOLLO_CLIENT_NAME,
    scopesLink,
    ascensionLink,
  );

  return new ApolloClient({
    cache: fragment?.possibleTypes
      ? new InMemoryCache({ possibleTypes: fragment.possibleTypes })
      : new InMemoryCache(),
    link: ApolloLink.from([retryLink, resetTokenOnErrorLink, scopeOrAscensionLink]),
  });
};
