import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonSize, Col, Row } from '@estimateone/frontend-components';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import { sortQuotesByDate } from '@itp-subbie/features/itp-quotes/QuotesTables/utils';
import GlobalErrorList from '../../../../shared/FinalForm/GlobalErrorList';
import { TransformedFile, UploadContext } from '@shared/FileUploader';
import { ItpButtonStyleWrapper, ItpButtonType } from '@shared/Itp/ItpStyleWrapper';
import { QuoteSectionWithQuoteFileUploader } from '@shared/Quotes/Form/QuoteFormQuoteSection';
import { focusOnQuoteErrorsDecorator } from '@shared/Quotes/Form/decorators';
import { QuoteFormCommonFieldErrors, QuoteFormCommonFieldValues } from '@shared/Quotes/Form/types';
import { validateQuoteFormCommonFields } from '@shared/Quotes/Form/validators';
import { useItpSubbieContext } from '../../../context';
import { Action, AnalyticsEndpoint, Event, useAnalytics } from '../../../../hooks/Analytics';
import { useCreateOrReviseQuote } from './hooks';
import { QuoteFormCommonFieldName, UploadParentType } from '@ascension/enums';
import {
  QuoteSubbieAddFormValues,
  SubbieAddQuoteFormErrors,
} from '@subbie/features/QuotesFeature/AddQuoteForm/types';
import styles from './styles.scss';

const FORM_ID = 'itp-subbie-create-quote-form';

const validate = (values: QuoteSubbieAddFormValues): SubbieAddQuoteFormErrors => {
  const commonQuoteErrors = validateQuoteFormCommonFields(values);

  return { ...commonQuoteErrors };
};

export const ItpCreateQuoteForm = ({ fileManagerLocale }: { fileManagerLocale: string }) => {
  const [{ stageId, rfqId, openSignupModal, quotesForRfq }] = useItpSubbieContext();
  const extraFiles = useMemo(
    () =>
      quotesForRfq
        ? sortQuotesByDate(quotesForRfq)[
            quotesForRfq.length - 1
          ]?.additionalFiles.map<TransformedFile>(({ id, fileName, fileSize, hash }) => ({
            id: id.toString(),
            name: fileName,
            size: fileSize,
            fileName,
            fileSize,
            hash,
          }))
        : null,
    [quotesForRfq],
  );

  const focusOnErrors = useMemo(
    () =>
      focusOnQuoteErrorsDecorator<QuoteFormCommonFieldValues, QuoteFormCommonFieldErrors>(FORM_ID),
    [],
  );

  const { submit: createOrReviseQuotes } = useCreateOrReviseQuote(rfqId, openSignupModal);

  const { addEvent } = useAnalytics(Event.INTERACT, AnalyticsEndpoint.Itp);

  const handleSubmitClicked = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({ action: Action.ITP_QUOTE_SUBMIT_CLICKED });
  };

  return (
    <Form
      onSubmit={createOrReviseQuotes}
      decorators={[focusOnErrors]}
      initialValues={{
        [QuoteFormCommonFieldName.ExtraFiles]: extraFiles,
      }}
      validate={(values): QuoteFormCommonFieldErrors => validate(values)}
      mutators={{
        ...arrayMutators,
        setFieldTouched,
      }}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form id={FORM_ID} onSubmit={handleSubmit}>
          <Row>
            <Col span={12}>{submitError && <GlobalErrorList errors={submitError} />}</Col>
          </Row>
          <QuoteSectionWithQuoteFileUploader
            context={UploadContext.ITP}
            fileManagerLocale={fileManagerLocale}
            parentId={stageId}
            parentType={UploadParentType.STAGE_QUOTE}
            extraFilesParentId={stageId}
            extraFilesParentType={UploadParentType.STAGE_QUOTE_EXTRA_FILE}
          />
          <hr className={styles.itpFormHr} />
          <div className={styles.itpFormSubmitBtnContainer}>
            <ItpButtonStyleWrapper buttonType={ItpButtonType.Primary}>
              <Button
                onClick={handleSubmitClicked}
                size={ButtonSize.Large}
                fullWidth
                disabled={submitting}
              >
                Submit
              </Button>
            </ItpButtonStyleWrapper>
          </div>
        </form>
      )}
    </Form>
  );
};
