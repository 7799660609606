import React, { ReactNode } from 'react';

const CheckboxGroup = ({
  children,
  errorText,
  label,
}: {
  children: ReactNode;
  errorText?: string;
  label?: string;
}) => (
  <div className={`form-group ${errorText ? 'has-error' : ''}`}>
    {label && <label className="control-label">{label}</label>}
    {children}
    {errorText && <span className="help-block">{errorText}</span>}
  </div>
);

export default CheckboxGroup;
