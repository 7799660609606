import { createContext, useContext } from 'react';

/**
 * Used to create a React context that should always be wrapped within a provider AND
 * should never return undefined as a "valid" context value. e.g. If the context can
 * be fully initialized without doing a graphql query, this can be used instead of
 * using React.createContext directly to remove undefined as a valid value for the
 * context. Do not use if you're deliberately returning a value of 'undefined' from
 * the provider itself while waiting for graphql queries to resolve.
 *
 * Based on Kent C Dodd's "How to use React context effectively":
 *   https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
export const createGenericContext = <T>(contextName: string) => {
  // Create a context with a generic parameter or undefined
  const genericContext = createContext<T | undefined>(undefined);

  // Check if the value provided to the context is defined or throw an error
  const useGenericContext = () => {
    const contextIsDefined = useContext(genericContext);
    if (!contextIsDefined) {
      throw new Error(`${contextName}Context must be used within a ${contextName}Provider`);
    }
    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider] as const;
};
