import React from 'react';
import { Modal } from '@estimateone/frontend-components';
import { ItpModalButtons } from './ItpModalButtons';
import { Event, Action, useAnalytics, AnalyticsEndpoint } from '../../../../hooks/Analytics';

type QuoteDeleteModalProps = {
  isOpen: boolean;
  removeModal: () => void;
  handleDelete: () => void;
};

export const ItpDeleteQuoteModal: React.VFC<QuoteDeleteModalProps> = ({
  isOpen = false,
  handleDelete,
  removeModal,
}) => {
  const { addEvent } = useAnalytics(Event.INTERACT, AnalyticsEndpoint.Itp);

  const handleSubmit = async () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({ action: Action.ITP_QUOTE_DELETE_CLICKED });
    handleDelete();
    removeModal();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={removeModal} rootElement="body">
      <Modal.Section>
        <h2>Delete Quote</h2>
        <p>Are you sure you want to delete this quote and all of its revisions?</p>
        <ItpModalButtons
          submitBtnTitle="Yes, delete quote"
          submitOnClick={handleSubmit}
          cancelOnClick={removeModal}
        />
      </Modal.Section>
    </Modal>
  );
};
