import React from 'react';
import { Card, CardVariant, joinClassNames } from '@estimateone/frontend-components';
import { Pricing } from '../../../fec';
import { OrderItemVariant } from './enum';
import styles from './styles.scss';

type Item = {
  id: number;
  name: string;
  price: number;
  priceExGST: number;
  shortDescription?: string;
  variant?: OrderItemVariant;
};

type OrderItemProps = {
  item: Item;
  showShortDescription?: boolean;
};

type OrderSummaryCardProps = {
  items: Item[];
  isMonthly?: boolean;
  showShortDescription?: boolean;
};

const getTotal = (prop: keyof Pick<Item, 'price' | 'priceExGST'>) => (items: Item[]) =>
  items.reduce((sum, item) => sum + item[prop], 0);

const CardHeaderMonthly: React.VFC = () => (
  <>
    <h3>Your monthly costs</h3>
    <div className={styles.orderPaymentText}>
      <p>Auto debited every month</p>
      <p>Cancel at any time</p>
    </div>
  </>
);

const CardHeaderAnnual: React.VFC = () => (
  <>
    <h3>Your annual costs</h3>
    <div className={styles.orderPaymentText}>
      <p>Auto debited every year</p>
    </div>
  </>
);

const OrderItem: React.VFC<OrderItemProps> = ({ item, showShortDescription = false }) => {
  const showDescription = showShortDescription && item.shortDescription !== null;

  return (
    <>
      <li className={joinClassNames(item.variant && styles[item.variant])}>
        <span className={styles.label}>{item.name}</span>
        <Pricing price={item.priceExGST} />
      </li>
      {showDescription && (
        <li>
          <span className={styles.descriptionLabel}>{item.shortDescription}</span>
        </li>
      )}
    </>
  );
};

const OrderSummaryCard: React.VFC<OrderSummaryCardProps> = ({
  items,
  isMonthly = true,
  showShortDescription = false,
}) => {
  const itemsTotal = getTotal('price')(items);
  const itemsTotalExclGST = getTotal('priceExGST')(items);

  return (
    <Card variant={CardVariant.Grey}>
      <Card.Body isWhite>
        {isMonthly ? <CardHeaderMonthly /> : <CardHeaderAnnual />}
        <ul className={joinClassNames(styles.itemList, styles.orderItems)}>
          {items.map((item) => (
            <OrderItem
              key={`order-summary-item-${item.id}-${item.name}`}
              item={item}
              showShortDescription={showShortDescription}
            />
          ))}
        </ul>

        <ul className={styles.itemList}>
          <li>
            <span className={styles.label}>Subtotal:</span>
            <Pricing price={itemsTotalExclGST} />
          </li>
          <li>
            <span className={styles.label}>GST:</span>
            <Pricing price={itemsTotal - itemsTotalExclGST} />
          </li>
        </ul>

        <ul className={`${styles.itemList} ${styles.total}`}>
          <li>
            <span className={styles.label}>Total:</span>
            <Pricing price={itemsTotal} />
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
};

export { OrderItemVariant };
export default OrderSummaryCard;
